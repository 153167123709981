import { CTAButton } from '@platform/website-components'
import { OlbyLogo } from '@platform/ui-kit'
import Image from 'next/image'

import BackgroundImage from './background.png'
import styles from './index.module.scss'

export const Index = () => {
  return (
    <div className={styles['wrapper']}>
      <Image src={BackgroundImage} alt="space" fill sizes="100vw" className={styles['image']} />
      <div className={styles['content']}>
        <h1 className={styles['title']}>
          <span className={styles['jungle']}>Jungle Devs</span>
          <br />
          is now
          <br />
          <span className={styles['visually-hidden']}>Olby</span>
        </h1>
        <OlbyLogo className={styles['logo']} />
        <CTAButton variant="white" size="medium" href="https://olby.com" isExternal>
          Launch Website
        </CTAButton>
      </div>
    </div>
  )
}

export default Index
