import type { ReactElement } from 'react'
import type { CategoryWithPostsAttributes } from '@platform/data-access'
import slugify from 'slugify'

import { REMOVE_DOUBLE_SPACE_REGEX } from './regex'

export const capitalizeString = (string?: string | null | undefined) => {
  if (!string) {
    return ''
  }

  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const formatCurrencyToNumber = (value: string | number | undefined) => {
  if (!value) {
    return 0
  }

  return typeof value === 'number' ? value : Number(value?.replaceAll?.(',', ''))
}

const numberFormatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' })
const numberFormatterNoDecimals = Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})
const numberFormatterBRL = Intl.NumberFormat('en', { style: 'currency', currency: 'BRL' })

export const valueToCurrency = (value: number, noDecimals?: boolean) =>
  `${(noDecimals ? numberFormatterNoDecimals : numberFormatter).format(value)} USD`
export const valueToBRLCurrency = (value: number) => numberFormatterBRL.format(value)

export const getCurrencyToInput = (value: string) => {
  if (value.charAt(0) === '$') {
    return value.slice(1)
  }

  return value.slice(0, 1) + value.slice(2)
}
export const appUrl =
  (typeof window !== 'undefined' && window.location?.host) ||
  process.env.NEXT_PUBLIC_TALENT_URL_HOST ||
  ''

export const isExternalUrl = (url: string | null | undefined) => {
  return (
    !(!url || url?.[0] === '/' || url?.[0] === '?' || url.includes(appUrl)) ||
    url?.includes('mailto:') ||
    url?.includes('blob')
  )
}

export const formatUrl = (url: string | null | undefined) => {
  if (!url) {
    return ''
  }

  if (url?.[0] === '/' || url?.[0] === '?' || url.includes('mailto:') || url.includes('blob')) {
    return url
  }

  if (url.includes(appUrl)) {
    return url.split(appUrl)[1]
  }

  if (url.includes('http')) {
    return url
  }

  if (url?.[0] === '#') {
    return url
  }

  return `https://${url}`
}

export const urlWithProtocol = (url: string | undefined | null) =>
  url && url.includes('http') ? url : `https://${url}`

const DEFAULT_BROWSER_SETTINGS = 'DEFAULT_BROWSER_SETTINGS'

export const getUserTheme = (selectedTheme?: string | null) => {
  // If user the theme to be default browser settings, check browser information
  if (selectedTheme === DEFAULT_BROWSER_SETTINGS || !selectedTheme) {
    const darkThemeMq =
      typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches

    return darkThemeMq ? 'dark' : 'light'
  }

  // Otherwise, just sent user selected value
  return selectedTheme?.toLocaleLowerCase()
}

export const isMainNavActiveRoute = (currentUrl: string, link: string) => {
  if (currentUrl === link) {
    return true
  }

  const currentUrlMainRoute = currentUrl.split('/').filter(route => route !== '')?.[0]
  const linkMainRoute = link.split('/').filter(route => route !== '')?.[0]

  if (linkMainRoute?.includes('?')) {
    const [linkMainRouterWithoutSort] = linkMainRoute.split('?')
    return currentUrlMainRoute === linkMainRouterWithoutSort
  }

  return currentUrlMainRoute === linkMainRoute
}

export const formatReferral = (referralOnUrl?: string | null, referralOnForm?: string) => {
  if (!referralOnUrl && !referralOnForm) {
    return undefined
  }

  return (referralOnUrl || referralOnForm)?.toLocaleLowerCase()
}

export const sanitizeStringSpaces = (str: string) => {
  // Remove spaces from string edges and duplicated spaces in the middle
  return str.trim().replace(REMOVE_DOUBLE_SPACE_REGEX, ' ')
}

export const replaceState = (newUrl: URL) => {
  window.history.replaceState(
    {
      ...window.history.state,
      as: newUrl.href,
      url: newUrl.href,
    },
    '',
    newUrl.href
  )
}

export const formatWebsitePostCategory = (categorySlug?: string | string[]) => {
  if (!categorySlug || Array.isArray(categorySlug)) {
    return ''
  }
  const category = categorySlug.replace(/-/, ' ')
  const categoryFormatted = category[0].toUpperCase() + category.substring(1)

  return categoryFormatted
}

type NestLayoutProps = {
  parent: (page: ReactElement) => JSX.Element
  child: (page: ReactElement) => JSX.Element
}

export const nestLayout =
  ({ parent, child }: NestLayoutProps) =>
  (page: ReactElement) => {
    return parent(child(page))
  }

export const formatPostsCategory = (categoryList?: CategoryWithPostsAttributes[] | null) => {
  if (!categoryList) {
    return {
      highlightedCategories: [],
      moreOptionsCategories: [],
    }
  }

  const formattedCategories = categoryList.map(category => ({
    label: category.name || '',
    href: `/blog/${slugify(category.name || '', { lower: true })}`,
  }))

  const [
    firstCategory,
    secondCategory,
    thirdCategory,
    fourthCategory,
    fifthCategory,
    sixthCategory,
    ...moreOptions
  ] = formattedCategories

  return {
    highlightedCategories: [
      firstCategory,
      ...(secondCategory ? [secondCategory] : []),
      ...(thirdCategory ? [thirdCategory] : []),
      ...(fourthCategory ? [fourthCategory] : []),
      ...(fifthCategory ? [fifthCategory] : []),
      ...(sixthCategory ? [sixthCategory] : []),
    ],
    moreOptionsCategories: moreOptions,
  }
}
