/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  join__FieldSet: any;
};

export enum AccountStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type AddCandidateInput = {
  candidate: SourcingCandidateInput;
  jobId: Scalars['ID'];
  recruiter: SourcingRecruiterInput;
};

export type AddJobCandidateInput = {
  jobId: Scalars['ID'];
  stage: JobCandidateStage;
  userIds: Array<Scalars['ID']>;
};

export type AddJobCandidateSourcingInput = {
  assigneeUserId: Scalars['ID'];
  candidate: JobCandidateSourcingInput;
  jobId: Scalars['ID'];
};

export type AddWorkExperienceInput = {
  company: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endCurrentPosition?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  ongoing: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type Author = {
  __typename?: 'Author';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Media>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthorFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<AuthorFiltersPartial>>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  or?: InputMaybe<Array<AuthorFiltersPartial>>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type AuthorFiltersPartial = {
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type AuthorSortArgsPartial = {
  createdAt?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  name?: InputMaybe<SortingDirection>;
  picture?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export enum Availability {
  Immediate = 'IMMEDIATE',
  OpenToOffers = 'OPEN_TO_OFFERS',
  Unavailable = 'UNAVAILABLE'
}

export type BackofficeUpdateAvailabilityInput = {
  availability: Availability;
  justification: Scalars['String'];
};

export type BackofficeUpdateProfileInput = {
  bitrixContactId?: InputMaybe<Scalars['Float']>;
  bitrixProfileReviewId?: InputMaybe<Scalars['Float']>;
  careerPath?: InputMaybe<CareerPath>;
  declinedAt?: InputMaybe<Scalars['DateTime']>;
  declinedFeedback?: InputMaybe<Scalars['String']>;
  englishProficiency?: InputMaybe<EnglishProficiency>;
  englishScore?: InputMaybe<Scalars['Float']>;
  /** Minimum expected salary */
  expectedSalaryFrom?: InputMaybe<Scalars['Float']>;
  /** Maximum expected salary. */
  expectedSalaryTo?: InputMaybe<Scalars['Float']>;
  githubProfile?: InputMaybe<Scalars['String']>;
  inactiveReason?: InputMaybe<Scalars['String']>;
  initialMatchingPrecheck?: InputMaybe<Scalars['Boolean']>;
  linkedinHandle?: InputMaybe<Scalars['String']>;
  linkedinProfile?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  platformMessage?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  preferredRoles?: InputMaybe<Array<ProfilePreferredRole>>;
  referral?: InputMaybe<ProfileReferralInput>;
  resume?: InputMaybe<Scalars['String']>;
  reviewStatus?: InputMaybe<ProfileReviewStatus>;
  softSkillsScore?: InputMaybe<Scalars['JSONObject']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  versionMetadata?: InputMaybe<ProfileVersionMetadataInput>;
  vettingFinishedAt?: InputMaybe<Scalars['DateTime']>;
  vettingStartedAt?: InputMaybe<Scalars['DateTime']>;
  vettingStatus?: InputMaybe<VettingStatus>;
  welcomeViewed?: InputMaybe<Scalars['Boolean']>;
  workableId?: InputMaybe<Scalars['String']>;
  workableRecruiterName?: InputMaybe<Scalars['String']>;
  workableStage?: InputMaybe<Scalars['String']>;
  workableUrl?: InputMaybe<Scalars['String']>;
  workingExperience?: InputMaybe<WorkingExperience>;
  yearsOfExperience?: InputMaybe<Scalars['Int']>;
};

export type BankIdentifier = {
  __typename?: 'BankIdentifier';
  address: Scalars['String'];
  name: Scalars['String'];
};

export type Billing = {
  __typename?: 'Billing';
  _id: Scalars['ID'];
  code: Scalars['Int'];
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  emailsSent: Scalars['Boolean'];
  invoicesZipUrl?: Maybe<Scalars['String']>;
  notes: Array<BillingNote>;
  numberForCompany: Scalars['Int'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  platformInvoice: Invoice;
  platformInvoiceId: Scalars['ID'];
  status: BillingStatus;
  statusHistory: Array<BillingStatusEntry>;
  statusSortingOrder?: Maybe<Scalars['Int']>;
  talentInvoiceIds: Array<Scalars['ID']>;
  talentInvoices: Array<Invoice>;
  talentsCount: Scalars['Int'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type BillingConnection = {
  __typename?: 'BillingConnection';
  /** Array of edges. */
  edges: Array<BillingEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type BillingContact = {
  __typename?: 'BillingContact';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use firstName and lastName instead. */
  name?: Maybe<Scalars['String']>;
};

export type BillingCycle = {
  __typename?: 'BillingCycle';
  _id: Scalars['ID'];
  closed: Scalars['Boolean'];
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  engagement: Engagement;
  engagementId: Scalars['ID'];
  expectedHours?: Maybe<Scalars['Float']>;
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['ID']>;
  overtimeJustification?: Maybe<Scalars['String']>;
  profile: Profile;
  startDate: Scalars['DateTime'];
  talentUserId: Scalars['ID'];
  totalSecondsSpent: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  worklogs?: Maybe<Array<Worklog>>;
};

export type BillingCycleConnection = {
  __typename?: 'BillingCycleConnection';
  /** Array of edges. */
  edges: Array<BillingCycleEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type BillingCycleEdge = {
  __typename?: 'BillingCycleEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the BillingCycle */
  node: BillingCycle;
};

export type BillingCycleFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<BillingCycleFilter>>;
  closed?: InputMaybe<BooleanFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  engagementId?: InputMaybe<IdFilterComparison>;
  expectedHours?: InputMaybe<FloatFieldComparison>;
  invoiceId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BillingCycleFilter>>;
  startDate?: InputMaybe<DateFieldComparison>;
  talentUserId?: InputMaybe<IdFilterComparison>;
};

export type BillingCycleSort = {
  direction: SortDirection;
  field: BillingCycleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BillingCycleSortFields {
  Id = '_id',
  Closed = 'closed',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  EngagementId = 'engagementId',
  ExpectedHours = 'expectedHours',
  InvoiceId = 'invoiceId',
  StartDate = 'startDate',
  TalentUserId = 'talentUserId'
}

export type BillingEdge = {
  __typename?: 'BillingEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Billing */
  node: Billing;
};

export type BillingFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<BillingFilter>>;
  code?: InputMaybe<IntFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  company__name?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  notes__note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BillingFilter>>;
  periodEnd?: InputMaybe<DateFieldComparison>;
  periodStart?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<BillingStatusFilterComparison>;
  statusSortingOrder?: InputMaybe<IntFieldComparison>;
  total?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type BillingNote = {
  __typename?: 'BillingNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user?: Maybe<User>;
};

export type BillingSort = {
  direction: SortDirection;
  field: BillingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BillingSortFields {
  Id = '_id',
  Code = 'code',
  CompanyId = 'companyId',
  CompanyName = 'company__name',
  CreatedAt = 'createdAt',
  DueDate = 'dueDate',
  NotesNote = 'notes__note',
  PeriodEnd = 'periodEnd',
  PeriodStart = 'periodStart',
  Status = 'status',
  StatusSortingOrder = 'statusSortingOrder',
  Total = 'total',
  UpdatedAt = 'updatedAt'
}

export enum BillingStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  PaymentReceived = 'PAYMENT_RECEIVED',
  TransferredToWallet = 'TRANSFERRED_TO_WALLET',
  TransferFailed = 'TRANSFER_FAILED',
  TransferInProgress = 'TRANSFER_IN_PROGRESS',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  WaitingForPaymentDelayed = 'WAITING_FOR_PAYMENT_DELAYED'
}

export type BillingStatusEntry = {
  __typename?: 'BillingStatusEntry';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  status: BillingStatus;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type BillingStatusFilterComparison = {
  eq?: InputMaybe<BillingStatus>;
  gt?: InputMaybe<BillingStatus>;
  gte?: InputMaybe<BillingStatus>;
  iLike?: InputMaybe<BillingStatus>;
  in?: InputMaybe<Array<BillingStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<BillingStatus>;
  lt?: InputMaybe<BillingStatus>;
  lte?: InputMaybe<BillingStatus>;
  neq?: InputMaybe<BillingStatus>;
  notILike?: InputMaybe<BillingStatus>;
  notIn?: InputMaybe<Array<BillingStatus>>;
  notLike?: InputMaybe<BillingStatus>;
};

export type BillingsTotalDueByStatus = {
  __typename?: 'BillingsTotalDueByStatus';
  delayed: Scalars['Float'];
  waiting: Scalars['Float'];
};

export type BlogAuthorAttributesData = {
  __typename?: 'BlogAuthorAttributesData';
  data?: Maybe<Array<Author>>;
  pagination?: Maybe<Pagination>;
};

export type BlogAuthorQueryArgs = {
  filters?: InputMaybe<AuthorFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<AuthorSortArgsPartial>>;
};

export type BlogCategoryGuidesQueryArgs = {
  filters?: InputMaybe<GuideFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  minimumLength?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<GuideSortArgsPartial>>;
};

export type BlogCategoryPostsQueryArgs = {
  filters?: InputMaybe<PostFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  minimumLength?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<PostSortArgsPartial>>;
};

export type BlogCategoryQueryArgs = {
  filters?: InputMaybe<CategoryFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<CategorySortArgsPartial>>;
};

export type BlogCategoryWithGuidesQueryArgs = {
  filters?: InputMaybe<CategoryFilterArgsPartialWithConditionalFilters>;
  guides?: InputMaybe<BlogCategoryGuidesQueryArgs>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<CategorySortArgsPartial>>;
};

export type BlogCategoryWithPostsQueryArgs = {
  filters?: InputMaybe<CategoryFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  posts?: InputMaybe<BlogCategoryPostsQueryArgs>;
  sort?: InputMaybe<Array<CategorySortArgsPartial>>;
};

export type BlogFaqAttributesData = {
  __typename?: 'BlogFAQAttributesData';
  data?: Maybe<Array<Faq>>;
  pagination?: Maybe<Pagination>;
};

export type BlogFaQsQueryArgs = {
  filters?: InputMaybe<FaqFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<FaqSortArgsPartial>>;
};

export type BlogGuideQueryArgs = {
  filters?: InputMaybe<GuideFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<GuideSortArgsPartial>>;
};

export type BlogMainPageAttributes = {
  __typename?: 'BlogMainPageAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  featuredPost?: Maybe<BlogPostEntry>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogMainPageAttributesData = {
  __typename?: 'BlogMainPageAttributesData';
  data?: Maybe<BlogMainPageAttributes>;
};

export type BlogMainPageQueryArgs = {
  locale?: InputMaybe<Locale>;
};

export type BlogPostEntry = {
  __typename?: 'BlogPostEntry';
  post?: Maybe<FullPostAttributes>;
};

export type BlogPostQueryArgs = {
  filters?: InputMaybe<PostFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<PostSortArgsPartial>>;
};

export type BlogTagAttributesData = {
  __typename?: 'BlogTagAttributesData';
  data?: Maybe<Array<Tag>>;
  pagination?: Maybe<Pagination>;
};

export type BlogTagsQueryArgs = {
  filters?: InputMaybe<TagsFilterArgsPartialWithConditionalFilters>;
  locale?: InputMaybe<Locale>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<TagsSortArgsPartial>>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type BulkUpdateProfileSkillsInput = {
  updateData: Array<UpdateProfileSkillInput>;
  versionMetadata?: InputMaybe<ProfileVersionMetadataInput>;
};

export enum CareerPath {
  Generalist = 'GENERALIST',
  Leader = 'LEADER',
  Specialist = 'SPECIALIST'
}

export type Category = {
  __typename?: 'Category';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryAttributes = {
  __typename?: 'CategoryAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryAttributesData = {
  __typename?: 'CategoryAttributesData';
  data?: Maybe<Array<CategoryAttributes>>;
  pagination?: Maybe<Pagination>;
};

export type CategoryFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<CategoryFiltersPartial>>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  or?: InputMaybe<Array<CategoryFiltersPartial>>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type CategoryFiltersPartial = {
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type CategorySortArgsPartial = {
  createdAt?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  name?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export type CategoryWithGuidesAttributes = {
  __typename?: 'CategoryWithGuidesAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  guides?: Maybe<Array<FullGuideAttributes>>;
  guidesPagination?: Maybe<Pagination>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryWithGuidesAttributesData = {
  __typename?: 'CategoryWithGuidesAttributesData';
  data?: Maybe<Array<CategoryWithGuidesAttributes>>;
  pagination?: Maybe<Pagination>;
};

export type CategoryWithPostsAttributes = {
  __typename?: 'CategoryWithPostsAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<FullPostAttributes>>;
  postsPagination?: Maybe<Pagination>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryWithPostsAttributesData = {
  __typename?: 'CategoryWithPostsAttributesData';
  data?: Maybe<Array<CategoryWithPostsAttributes>>;
  pagination?: Maybe<Pagination>;
};

export type Certification = {
  __typename?: 'Certification';
  _id: Scalars['ID'];
  authority?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displaySource?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type CertificationInput = {
  authority?: InputMaybe<Scalars['String']>;
  displaySource?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type CheckDuplicateInput = {
  linkedinUrl?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CheckSourcingDuplicateInput = {
  linkedinProfile?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['ID']>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  activeEngagementsCount?: Maybe<Scalars['Int']>;
  businessType?: Maybe<Scalars['String']>;
  contacts: Array<CompanyContact>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  engagementManager?: Maybe<User>;
  engagementManagerId?: Maybe<Scalars['ID']>;
  /** @deprecated Companies must have a single engagement manager. Use engagementManagerId instead. */
  engagementUserIds?: Maybe<Array<Scalars['ID']>>;
  /** @deprecated Companies must have a single engagement manager. Use engagementManager instead. */
  engagementUsers?: Maybe<Array<User>>;
  joinedOn?: Maybe<Scalars['DateTime']>;
  legalEntity?: Maybe<LegalEntity>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Array<CompanyNote>>;
  /** @deprecated Use pictureUrl instead. */
  picture?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  slackChannelUrl?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatus>;
  teamSize?: Maybe<TeamSize>;
  updatedAt: Scalars['DateTime'];
  userIds: Array<Scalars['ID']>;
  userInvitationIds: Array<Scalars['ID']>;
  userInvitations: Array<CompanyUserInvitation>;
  users?: Maybe<Array<User>>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Array of edges. */
  edges: Array<CompanyEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type CompanyContact = {
  __typename?: 'CompanyContact';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use firstName and lastName instead. */
  name?: Maybe<Scalars['String']>;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Company */
  node: Company;
};

export type CompanyEngagementUserIdsFilterComparison = {
  in?: InputMaybe<Array<Scalars['ID']>>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  activeEngagementsCount?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<CompanyFilter>>;
  businessType?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  engagementManagerId?: InputMaybe<IdFilterComparison>;
  engagementUserIds?: InputMaybe<CompanyEngagementUserIdsFilterComparison>;
  joinedOn?: InputMaybe<DateFieldComparison>;
  location?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  notes__note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  status?: InputMaybe<CompanyStatusFilterComparison>;
  teamSize?: InputMaybe<TeamSizeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  userIds?: InputMaybe<CompanyUserIdsFilterComparison>;
};

export type CompanyInvitationInput = {
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CompanyInvitedUserSignUpInput = {
  acceptedTerms?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  invitationId: Scalars['ID'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type CompanyMoodPulse = IMoodPulse & {
  __typename?: 'CompanyMoodPulse';
  _id: Scalars['ID'];
  comment: Scalars['String'];
  companyId: Scalars['ID'];
  date: Scalars['DateTime'];
  engagement: Engagement;
  role: Role;
  score: Scalars['Float'];
  userId: Scalars['ID'];
};

export type CompanyNote = {
  __typename?: 'CompanyNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user: User;
};

export enum CompanyOptionalPopulates {
  ActiveEngagementsCount = 'activeEngagementsCount'
}

export enum CompanyPlatformService {
  CompanyKit = 'COMPANY_KIT',
  GiveBonus = 'GIVE_BONUS',
  ScaleTeam = 'SCALE_TEAM',
  TalentRetention = 'TALENT_RETENTION'
}

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  Id = '_id',
  ActiveEngagementsCount = 'activeEngagementsCount',
  BusinessType = 'businessType',
  CreatedAt = 'createdAt',
  EngagementManagerId = 'engagementManagerId',
  EngagementUserIds = 'engagementUserIds',
  JoinedOn = 'joinedOn',
  Location = 'location',
  Name = 'name',
  NotesNote = 'notes__note',
  Status = 'status',
  TeamSize = 'teamSize',
  UpdatedAt = 'updatedAt',
  UserIds = 'userIds'
}

export enum CompanyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CompanyStatusFilterComparison = {
  eq?: InputMaybe<CompanyStatus>;
  gt?: InputMaybe<CompanyStatus>;
  gte?: InputMaybe<CompanyStatus>;
  iLike?: InputMaybe<CompanyStatus>;
  in?: InputMaybe<Array<CompanyStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<CompanyStatus>;
  lt?: InputMaybe<CompanyStatus>;
  lte?: InputMaybe<CompanyStatus>;
  neq?: InputMaybe<CompanyStatus>;
  notILike?: InputMaybe<CompanyStatus>;
  notIn?: InputMaybe<Array<CompanyStatus>>;
  notLike?: InputMaybe<CompanyStatus>;
};

export type CompanyUserIdsFilterComparison = {
  in?: InputMaybe<Array<Scalars['ID']>>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanyUserInvitation = {
  __typename?: 'CompanyUserInvitation';
  _id: Scalars['ID'];
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  invitedBy: User;
  invitedById: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  status?: Maybe<InvitationStatus>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type CompanyUserInvitationConnection = {
  __typename?: 'CompanyUserInvitationConnection';
  /** Array of edges. */
  edges: Array<CompanyUserInvitationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type CompanyUserInvitationEdge = {
  __typename?: 'CompanyUserInvitationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the CompanyUserInvitation */
  node: CompanyUserInvitation;
};

export type CompanyUserInvitationFilter = {
  and?: InputMaybe<Array<CompanyUserInvitationFilter>>;
  companyId?: InputMaybe<IdFilterComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  invitedById?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyUserInvitationFilter>>;
  status?: InputMaybe<InvitationStatusFilterComparison>;
};

export type CompanyUserInvitationSort = {
  direction: SortDirection;
  field: CompanyUserInvitationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyUserInvitationSortFields {
  CompanyId = 'companyId',
  Email = 'email',
  FirstName = 'firstName',
  InvitedById = 'invitedById',
  LastName = 'lastName',
  Status = 'status'
}

export type CompanyUserSignUpInput = {
  acceptedTerms: Scalars['Boolean'];
  companyName: Scalars['String'];
  deviceId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ConfirmForgotPasswordInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ContentResult = {
  __typename?: 'ContentResult';
  authors: Array<Scalars['String']>;
  contentType: LearningContentType;
  coverUrl: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  topics: Array<Topics>;
  url: Scalars['String'];
};

export enum ContractType {
  DirectPlacement = 'DIRECT_PLACEMENT',
  MsaSow = 'MSA_SOW'
}

export type ContractTypeFilterComparison = {
  eq?: InputMaybe<ContractType>;
  gt?: InputMaybe<ContractType>;
  gte?: InputMaybe<ContractType>;
  iLike?: InputMaybe<ContractType>;
  in?: InputMaybe<Array<ContractType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ContractType>;
  lt?: InputMaybe<ContractType>;
  lte?: InputMaybe<ContractType>;
  neq?: InputMaybe<ContractType>;
  notILike?: InputMaybe<ContractType>;
  notIn?: InputMaybe<Array<ContractType>>;
  notLike?: InputMaybe<ContractType>;
};

export type CreateBackofficeUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<Group>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  slackHandle: Scalars['String'];
};

export type CreateBillingCycleInput = {
  closed?: InputMaybe<Scalars['Boolean']>;
  endDate: Scalars['DateTime'];
  engagementId: Scalars['ID'];
  expectedHoursPerWeek?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
};

export type CreateCompanyInput = {
  businessType?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<CreateContactInput>>;
  description?: InputMaybe<Scalars['String']>;
  engagementManagerId?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pictureKey?: InputMaybe<Scalars['String']>;
  slackChannelUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatus>;
  teamSize?: InputMaybe<TeamSize>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateContactInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateCustomInvoiceInput = {
  companyId: Scalars['ID'];
  dueDate: Scalars['DateTime'];
  items: Array<CreateInvoiceItemInput>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  talentUserId?: InputMaybe<Scalars['ID']>;
};

export type CreateEngagementInput = {
  code: Scalars['String'];
  companyContractSigned?: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  contractType: ContractType;
  endDate?: InputMaybe<Scalars['DateTime']>;
  engagementManagerUserId: Scalars['ID'];
  expectedHoursPerWeek?: InputMaybe<Scalars['Float']>;
  experienceStartDate?: InputMaybe<Scalars['DateTime']>;
  externalPayment?: InputMaybe<Scalars['Boolean']>;
  externalTimeTracking?: InputMaybe<ExternalTimeTrackingConfigInput>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  internalMetadata?: InputMaybe<Scalars['JSONObject']>;
  invoiceDuePeriod: Scalars['Int'];
  invoiceDueType: InvoiceDueType;
  jobCode: Scalars['String'];
  jobId?: InputMaybe<Scalars['ID']>;
  monthlySalary?: InputMaybe<Scalars['Float']>;
  offerMadeAt?: InputMaybe<Scalars['DateTime']>;
  paymentCycle: PaymentCycle;
  paymentType: PaymentType;
  serviceAgreementKeys?: InputMaybe<Array<Scalars['String']>>;
  serviceType: ServiceType;
  slackChannelUrl: Scalars['String'];
  sowNumber?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  takeRate: Scalars['Float'];
  talentContractSigned?: InputMaybe<Scalars['Boolean']>;
  talentUserId: Scalars['ID'];
  workContractType: WorkContractType;
};

export type CreateFinancialEntityInput = {
  addressComplement?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  cnpj: Scalars['String'];
  companyName: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  hasSetupHusky: Scalars['Boolean'];
  huskyConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  huskyId?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  talentFullName: Scalars['String'];
};

export type CreateInvoiceItemInput = {
  chargeTakeRate?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  engagementIds?: InputMaybe<Array<Scalars['ID']>>;
  value: Scalars['Float'];
  workedHours?: InputMaybe<Scalars['Float']>;
};

export type CreateJobInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  benefits?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  contractDurationInMonths?: InputMaybe<Scalars['Int']>;
  contractType?: InputMaybe<ContractType>;
  engagementManagerUserId: Scalars['ID'];
  expectedSalaryFrom: Scalars['Float'];
  expectedSalaryTo: Scalars['Float'];
  generalInformation?: InputMaybe<Scalars['String']>;
  hideSalaryFromPublic?: InputMaybe<Scalars['Boolean']>;
  mustHaveRequirements?: InputMaybe<Scalars['String']>;
  niceToHaveRequirements?: InputMaybe<Scalars['String']>;
  paymentCycle?: InputMaybe<PaymentCycle>;
  paymentType?: InputMaybe<PaymentType>;
  position: Scalars['String'];
  responsibilities?: InputMaybe<Scalars['String']>;
  sellingPoints?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<Seniority>;
  skills: Array<CreateJobSkillInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  workloadHoursPerWeek?: InputMaybe<Scalars['Int']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CreateJobSkillInput = {
  experienceYears?: InputMaybe<Scalars['Int']>;
  skillId: Scalars['ID'];
};

export type CreateLegalEntityInput = {
  addressComplement?: InputMaybe<Scalars['String']>;
  billingContacts: Array<CreateContactInput>;
  city: Scalars['String'];
  companyId: Scalars['String'];
  country: Scalars['String'];
  employeeIdentificationNumber: Scalars['String'];
  name: Scalars['String'];
  neighborhood?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
};

export type CreateSkillInput = {
  availableOnVetting?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Image URL */
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  /** Secondary Image URL */
  secondaryImage?: InputMaybe<Scalars['String']>;
  secondaryName?: InputMaybe<Scalars['String']>;
  workableShortCode?: InputMaybe<Scalars['String']>;
};

export type CreateTalentInvoiceInput = {
  code: Scalars['String'];
  cycleId: Scalars['ID'];
  dueDate: Scalars['DateTime'];
  engagementId: Scalars['ID'];
  externalId?: InputMaybe<Scalars['String']>;
  externalPayment?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<CreateInvoiceItemInput>>;
  number: Scalars['Int'];
  pdfKey?: InputMaybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  status: InvoiceStatus;
  total: Scalars['Float'];
  totalBRL?: InputMaybe<Scalars['Float']>;
  worklogIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateTermInput = {
  current: Scalars['Boolean'];
  role: Role;
  textHtml?: InputMaybe<Scalars['String']>;
  type: TermType;
  /** PDF/Page URL */
  url?: InputMaybe<Scalars['String']>;
  versionDate: Scalars['DateTime'];
  versionName: Scalars['String'];
};

export type CreateWorklogInput = {
  category: WorklogCategory;
  description?: InputMaybe<Scalars['String']>;
  engagementId: Scalars['ID'];
  overtimeJustification?: InputMaybe<Scalars['String']>;
  secondsSpent: Scalars['Int'];
  workedAt: Scalars['DateTime'];
};

export type CreditOfEntity = {
  __typename?: 'CreditOfEntity';
  account: Scalars['String'];
  address: Scalars['String'];
  addressLine2: Scalars['String'];
  name: Scalars['String'];
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DateFiltersPartial = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Array<Scalars['DateTime']>>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Array<Scalars['DateTime']>>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Education = {
  __typename?: 'Education';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  school: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type EducationInput = {
  endDate: Scalars['DateTime'];
  fieldOfStudy: Scalars['String'];
  level?: InputMaybe<Scalars['String']>;
  school: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type Engagement = {
  __typename?: 'Engagement';
  _id: Scalars['ID'];
  code: Scalars['String'];
  company: Company;
  companyContractSigned: Scalars['Boolean'];
  companyContractSignedDate?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['ID'];
  contractType: ContractType;
  createdAt: Scalars['DateTime'];
  currentCycle?: Maybe<BillingCycle>;
  currentCycleId?: Maybe<Scalars['ID']>;
  cyclesCreatedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  engagementManagerUser: User;
  engagementManagerUserId: Scalars['ID'];
  expectedHoursPerWeek?: Maybe<Scalars['Float']>;
  experienceStartDate?: Maybe<Scalars['DateTime']>;
  /** Whether this invoice is paid outside the platform or not. */
  externalPayment: Scalars['Boolean'];
  externalTimeTracking?: Maybe<ExternalTimeTrackingConfig>;
  hourlyRate?: Maybe<Scalars['Float']>;
  internalMetadata?: Maybe<Scalars['JSONObject']>;
  /** @deprecated There are other types besides Business Days, let's use invoiceDuePeriod and invoiceDueType */
  invoiceDueBusinessDays?: Maybe<Scalars['Int']>;
  invoiceDuePeriod: Scalars['Int'];
  invoiceDueType: InvoiceDueType;
  jobCode?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['ID']>;
  monthlySalary?: Maybe<Scalars['Float']>;
  nextCycle?: Maybe<BillingCycle>;
  nextCycleId?: Maybe<Scalars['ID']>;
  nextEngagement?: Maybe<Engagement>;
  nextEngagementId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Array<EngagementNote>>;
  offerMadeAt?: Maybe<Scalars['DateTime']>;
  paymentCycle: PaymentCycle;
  paymentType: PaymentType;
  previousEngagement?: Maybe<Engagement>;
  previousEngagementId?: Maybe<Scalars['ID']>;
  profile: Profile;
  serviceAgreementFileNames?: Maybe<Array<Scalars['String']>>;
  serviceAgreementKeys?: Maybe<Array<Scalars['String']>>;
  serviceAgreementUrls?: Maybe<Array<Scalars['String']>>;
  serviceType: ServiceType;
  slackChannelUrl?: Maybe<Scalars['String']>;
  sowNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: EngagementStatus;
  takeRate?: Maybe<Scalars['Float']>;
  talentContractSigned: Scalars['Boolean'];
  talentContractSignedDate?: Maybe<Scalars['DateTime']>;
  talentUserId: Scalars['ID'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  workContractType: WorkContractType;
};

export type EngagementConnection = {
  __typename?: 'EngagementConnection';
  /** Array of edges. */
  edges: Array<EngagementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EngagementEdge = {
  __typename?: 'EngagementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Engagement */
  node: Engagement;
};

export type EngagementFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<EngagementFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  companyContractSigned?: InputMaybe<BooleanFieldComparison>;
  companyContractSignedDate?: InputMaybe<DateFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  company__name?: InputMaybe<StringFieldComparison>;
  contractType?: InputMaybe<ContractTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentCycleId?: InputMaybe<IdFilterComparison>;
  cyclesCreatedAt?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  engagementManagerUserId?: InputMaybe<IdFilterComparison>;
  expectedHoursPerWeek?: InputMaybe<FloatFieldComparison>;
  experienceStartDate?: InputMaybe<DateFieldComparison>;
  externalPayment?: InputMaybe<BooleanFieldComparison>;
  hourlyRate?: InputMaybe<FloatFieldComparison>;
  invoiceDueBusinessDays?: InputMaybe<IntFieldComparison>;
  invoiceDuePeriod?: InputMaybe<IntFieldComparison>;
  jobCode?: InputMaybe<StringFieldComparison>;
  jobId?: InputMaybe<IdFilterComparison>;
  manager__email?: InputMaybe<StringFieldComparison>;
  manager__firstName?: InputMaybe<StringFieldComparison>;
  manager__fullName?: InputMaybe<StringFieldComparison>;
  manager__lastName?: InputMaybe<StringFieldComparison>;
  monthlySalary?: InputMaybe<FloatFieldComparison>;
  nextCycleId?: InputMaybe<IdFilterComparison>;
  nextEngagementId?: InputMaybe<IdFilterComparison>;
  notes__note?: InputMaybe<StringFieldComparison>;
  offerMadeAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<EngagementFilter>>;
  paymentCycle?: InputMaybe<PaymentCycleFilterComparison>;
  paymentType?: InputMaybe<PaymentTypeFilterComparison>;
  previousEngagementId?: InputMaybe<IdFilterComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<EngagementStatusFilterComparison>;
  takeRate?: InputMaybe<FloatFieldComparison>;
  talentContractSigned?: InputMaybe<BooleanFieldComparison>;
  talentContractSignedDate?: InputMaybe<DateFieldComparison>;
  talentUserId?: InputMaybe<IdFilterComparison>;
  talent__email?: InputMaybe<StringFieldComparison>;
  talent__firstName?: InputMaybe<StringFieldComparison>;
  talent__fullName?: InputMaybe<StringFieldComparison>;
  talent__lastName?: InputMaybe<StringFieldComparison>;
  terminationDate?: InputMaybe<DateFieldComparison>;
  workContractType?: InputMaybe<WorkContractTypeFilterComparison>;
};

export type EngagementNote = {
  __typename?: 'EngagementNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user?: Maybe<User>;
};

export type EngagementSort = {
  direction: SortDirection;
  field: EngagementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EngagementSortFields {
  Id = '_id',
  Code = 'code',
  CompanyContractSigned = 'companyContractSigned',
  CompanyContractSignedDate = 'companyContractSignedDate',
  CompanyId = 'companyId',
  CompanyName = 'company__name',
  ContractType = 'contractType',
  CreatedAt = 'createdAt',
  CurrentCycleId = 'currentCycleId',
  CyclesCreatedAt = 'cyclesCreatedAt',
  EndDate = 'endDate',
  EngagementManagerUserId = 'engagementManagerUserId',
  ExpectedHoursPerWeek = 'expectedHoursPerWeek',
  ExperienceStartDate = 'experienceStartDate',
  ExternalPayment = 'externalPayment',
  HourlyRate = 'hourlyRate',
  InvoiceDueBusinessDays = 'invoiceDueBusinessDays',
  InvoiceDuePeriod = 'invoiceDuePeriod',
  JobCode = 'jobCode',
  JobId = 'jobId',
  ManagerEmail = 'manager__email',
  ManagerFirstName = 'manager__firstName',
  ManagerFullName = 'manager__fullName',
  ManagerLastName = 'manager__lastName',
  MonthlySalary = 'monthlySalary',
  NextCycleId = 'nextCycleId',
  NextEngagementId = 'nextEngagementId',
  NotesNote = 'notes__note',
  OfferMadeAt = 'offerMadeAt',
  PaymentCycle = 'paymentCycle',
  PaymentType = 'paymentType',
  PreviousEngagementId = 'previousEngagementId',
  ServiceType = 'serviceType',
  StartDate = 'startDate',
  Status = 'status',
  TakeRate = 'takeRate',
  TalentContractSigned = 'talentContractSigned',
  TalentContractSignedDate = 'talentContractSignedDate',
  TalentUserId = 'talentUserId',
  TalentEmail = 'talent__email',
  TalentFirstName = 'talent__firstName',
  TalentFullName = 'talent__fullName',
  TalentLastName = 'talent__lastName',
  TerminationDate = 'terminationDate',
  WorkContractType = 'workContractType'
}

export enum EngagementStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  StartingSoon = 'STARTING_SOON',
  Terminated = 'TERMINATED',
  TrialPeriod = 'TRIAL_PERIOD'
}

export type EngagementStatusFilterComparison = {
  eq?: InputMaybe<EngagementStatus>;
  gt?: InputMaybe<EngagementStatus>;
  gte?: InputMaybe<EngagementStatus>;
  iLike?: InputMaybe<EngagementStatus>;
  in?: InputMaybe<Array<EngagementStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<EngagementStatus>;
  lt?: InputMaybe<EngagementStatus>;
  lte?: InputMaybe<EngagementStatus>;
  neq?: InputMaybe<EngagementStatus>;
  notILike?: InputMaybe<EngagementStatus>;
  notIn?: InputMaybe<Array<EngagementStatus>>;
  notLike?: InputMaybe<EngagementStatus>;
};

export enum EnglishProficiency {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE'
}

export enum Evaluation {
  Helpful = 'HELPFUL',
  NotHelpful = 'NOT_HELPFUL'
}

export type EvaluationInput = {
  evaluation?: InputMaybe<Evaluation>;
};

export type ExternalTimeTracking = {
  __typename?: 'ExternalTimeTracking';
  tool?: Maybe<TimeTrackingTool>;
  trackingId?: Maybe<Scalars['String']>;
};

export type ExternalTimeTrackingConfig = {
  __typename?: 'ExternalTimeTrackingConfig';
  config?: Maybe<Scalars['JSONObject']>;
  tool?: Maybe<TimeTrackingTool>;
  trackingId?: Maybe<Scalars['String']>;
};

export type ExternalTimeTrackingConfigInput = {
  config?: InputMaybe<Scalars['JSONObject']>;
  tool?: InputMaybe<TimeTrackingTool>;
  trackingId?: InputMaybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'FAQ';
  answer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FaqFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<FaqFiltersPartial>>;
  answer?: InputMaybe<StringFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  or?: InputMaybe<Array<FaqFiltersPartial>>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  question?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type FaqFiltersPartial = {
  answer?: InputMaybe<StringFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  question?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type FaqSortArgsPartial = {
  answer?: InputMaybe<SortingDirection>;
  createdAt?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  publishedAt?: InputMaybe<SortingDirection>;
  question?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export type FileObject = {
  __typename?: 'FileObject';
  content: Scalars['String'];
  fileName: Scalars['String'];
};

export type FinancialEntity = {
  __typename?: 'FinancialEntity';
  _id: Scalars['ID'];
  addressComplement?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  hasSetupHusky: Scalars['Boolean'];
  huskyConfirmationDate?: Maybe<Scalars['DateTime']>;
  huskyId?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  profile: Profile;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  talentFullName?: Maybe<Scalars['String']>;
  talentUserId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type FinancialEntityConnection = {
  __typename?: 'FinancialEntityConnection';
  /** Array of edges. */
  edges: Array<FinancialEntityEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type FinancialEntityEdge = {
  __typename?: 'FinancialEntityEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the FinancialEntity */
  node: FinancialEntity;
};

export type FinancialEntityFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FinancialEntityFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<FinancialEntityFilter>>;
  talentUserId?: InputMaybe<IdFilterComparison>;
};

export type FinancialEntitySort = {
  direction: SortDirection;
  field: FinancialEntitySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FinancialEntitySortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  TalentUserId = 'talentUserId'
}

export type FindCurrentByRoleAndTypeInput = {
  role: Role;
  type: TermType;
};

export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type Formats = {
  __typename?: 'Formats';
  large?: Maybe<MediaFormat>;
  medium?: Maybe<MediaFormat>;
  overwrittenLarge?: Maybe<MediaFormat>;
  overwrittenMedium?: Maybe<MediaFormat>;
  overwrittenSmall?: Maybe<MediaFormat>;
  overwrittenThumbnail?: Maybe<MediaFormat>;
  small?: Maybe<MediaFormat>;
  thumbnail?: Maybe<MediaFormat>;
};

export type FullGuideAttributes = {
  __typename?: 'FullGuideAttributes';
  article?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  evaluation?: Maybe<Evaluation>;
  featuredImage?: Maybe<Image>;
  helpfulScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  localizations?: Maybe<Array<LocalizationAttributesData>>;
  notHelpfulScore?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  readingTime?: Maybe<Scalars['Int']>;
  resume?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FullPostAttributes = {
  __typename?: 'FullPostAttributes';
  article?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  featuredImage?: Maybe<Image>;
  helpfulScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  localizations?: Maybe<Array<LocalizationAttributesData>>;
  notHelpfulScore?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  readingTime?: Maybe<Scalars['Int']>;
  resume?: Maybe<Scalars['String']>;
  seo?: Maybe<Seo>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum Group {
  Admin = 'ADMIN',
  Business = 'BUSINESS',
  Engagement = 'ENGAGEMENT',
  Financial = 'FINANCIAL',
  Growth = 'GROWTH'
}

export type GuideAttributeData = {
  __typename?: 'GuideAttributeData';
  data?: Maybe<FullGuideAttributes>;
};

export type GuideAttributesData = {
  __typename?: 'GuideAttributesData';
  data?: Maybe<Array<FullGuideAttributes>>;
  pagination?: Maybe<Pagination>;
};

export type GuideFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<GuideFilterPartial>>;
  article?: InputMaybe<StringFiltersPartial>;
  authors?: InputMaybe<AuthorFiltersPartial>;
  category?: InputMaybe<CategoryFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  helpfulScore?: InputMaybe<NumberFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  notHelpfulScore?: InputMaybe<NumberFiltersPartial>;
  or?: InputMaybe<Array<GuideFilterPartial>>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  readingTime?: InputMaybe<NumberFiltersPartial>;
  resume?: InputMaybe<StringFiltersPartial>;
  slug?: InputMaybe<StringFiltersPartial>;
  tags?: InputMaybe<TagsFiltersPartial>;
  title?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type GuideFilterPartial = {
  article?: InputMaybe<StringFiltersPartial>;
  authors?: InputMaybe<AuthorFiltersPartial>;
  category?: InputMaybe<CategoryFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  helpfulScore?: InputMaybe<NumberFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  notHelpfulScore?: InputMaybe<NumberFiltersPartial>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  readingTime?: InputMaybe<NumberFiltersPartial>;
  resume?: InputMaybe<StringFiltersPartial>;
  slug?: InputMaybe<StringFiltersPartial>;
  tags?: InputMaybe<TagsFiltersPartial>;
  title?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type GuideSortArgsPartial = {
  article?: InputMaybe<SortingDirection>;
  createdAt?: InputMaybe<SortingDirection>;
  helpfulScore?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  notHelpfulScore?: InputMaybe<SortingDirection>;
  publishedAt?: InputMaybe<SortingDirection>;
  readingTime?: InputMaybe<SortingDirection>;
  resume?: InputMaybe<SortingDirection>;
  slug?: InputMaybe<SortingDirection>;
  title?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export type IMoodPulse = {
  _id: Scalars['ID'];
  comment: Scalars['String'];
  companyId: Scalars['ID'];
  date: Scalars['DateTime'];
  engagement: Engagement;
  role: Role;
  score: Scalars['Float'];
  userId: Scalars['ID'];
};

export type Image = {
  __typename?: 'Image';
  authorName?: Maybe<Scalars['String']>;
  authorPortfolio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Media>;
  siteLink?: Maybe<Scalars['String']>;
  siteOfImage?: Maybe<Scalars['String']>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export enum InvitationStatus {
  Complete = 'COMPLETE',
  Invited = 'INVITED'
}

export type InvitationStatusFilterComparison = {
  eq?: InputMaybe<InvitationStatus>;
  gt?: InputMaybe<InvitationStatus>;
  gte?: InputMaybe<InvitationStatus>;
  iLike?: InputMaybe<InvitationStatus>;
  in?: InputMaybe<Array<InvitationStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<InvitationStatus>;
  lt?: InputMaybe<InvitationStatus>;
  lte?: InputMaybe<InvitationStatus>;
  neq?: InputMaybe<InvitationStatus>;
  notILike?: InputMaybe<InvitationStatus>;
  notIn?: InputMaybe<Array<InvitationStatus>>;
  notLike?: InputMaybe<InvitationStatus>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['ID'];
  billing?: Maybe<Billing>;
  billingCode?: Maybe<Scalars['Int']>;
  chargeableValue?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  cycle?: Maybe<BillingCycle>;
  cycleId?: Maybe<Scalars['ID']>;
  /** @deprecated User pdfUrl instead */
  document?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  /** Whether this invoice is paid outside the platform or not. */
  externalPayment: Scalars['Boolean'];
  financialEntity?: Maybe<FinancialEntity>;
  financialEntityId?: Maybe<Scalars['ID']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  /** Husky Bank Transfer Receipt */
  huskyBankTransferReceipt?: Maybe<Scalars['String']>;
  huskyBankTransferReceiptUrl?: Maybe<Scalars['String']>;
  /** Husky Errors */
  huskyErrors?: Maybe<Scalars['JSONObject']>;
  /** Husky Payment ID */
  huskyId?: Maybe<Scalars['String']>;
  /** Husky Status */
  huskyStatus?: Maybe<InvoiceHuskyStatus>;
  isCustomInvoice: Scalars['Boolean'];
  isPlatformInvoice: Scalars['Boolean'];
  items: Array<InvoiceItem>;
  legalEntity: LegalEntity;
  legalEntityId: Scalars['ID'];
  notes: Array<InvoiceNote>;
  number?: Maybe<Scalars['Int']>;
  pdfUrl?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  platformUser?: Maybe<PaymentPlatformUser>;
  profile?: Maybe<Profile>;
  rateBRL?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<ServiceType>;
  status: InvoiceStatus;
  statusHistory: Array<InvoiceStatusEntry>;
  takeRate?: Maybe<Scalars['Float']>;
  talentInvoiceIds?: Maybe<Array<Scalars['ID']>>;
  talentInvoices?: Maybe<Array<Invoice>>;
  talentUserId?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
  totalBRL?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  worklogIds: Array<Scalars['ID']>;
  worklogs: Array<Worklog>;
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** Array of edges. */
  edges: Array<InvoiceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export enum InvoiceDueType {
  BusinessDays = 'BUSINESS_DAYS',
  ConsecutiveDays = 'CONSECUTIVE_DAYS'
}

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Invoice */
  node: Invoice;
};

export type InvoiceFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<InvoiceFilter>>;
  billingCode?: InputMaybe<IntFieldComparison>;
  chargeableValue?: InputMaybe<FloatFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  company__name?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  cycleId?: InputMaybe<IdFilterComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  engagementId?: InputMaybe<IdFilterComparison>;
  externalPayment?: InputMaybe<BooleanFieldComparison>;
  financialEntityId?: InputMaybe<IdFilterComparison>;
  isCustomInvoice?: InputMaybe<BooleanFieldComparison>;
  isPlatformInvoice?: InputMaybe<BooleanFieldComparison>;
  legalEntityId?: InputMaybe<IdFilterComparison>;
  notes__note?: InputMaybe<StringFieldComparison>;
  number?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<InvoiceFilter>>;
  periodEnd?: InputMaybe<DateFieldComparison>;
  periodStart?: InputMaybe<DateFieldComparison>;
  rateBRL?: InputMaybe<FloatFieldComparison>;
  status?: InputMaybe<InvoiceStatusFilterComparison>;
  takeRate?: InputMaybe<FloatFieldComparison>;
  talentUserId?: InputMaybe<IdFilterComparison>;
  talent__email?: InputMaybe<StringFieldComparison>;
  talent__firstName?: InputMaybe<StringFieldComparison>;
  talent__fullName?: InputMaybe<StringFieldComparison>;
  talent__lastName?: InputMaybe<StringFieldComparison>;
  total?: InputMaybe<FloatFieldComparison>;
  totalBRL?: InputMaybe<FloatFieldComparison>;
};

export enum InvoiceHuskyStatus {
  AvailableToWithdraw = 'AVAILABLE_TO_WITHDRAW',
  ComplianceDocumentRequired = 'COMPLIANCE_DOCUMENT_REQUIRED',
  Creating = 'CREATING',
  Failed = 'FAILED',
  NoFundsAvailable = 'NO_FUNDS_AVAILABLE',
  Paid = 'PAID',
  Paying = 'PAYING',
  Processing = 'PROCESSING',
  RequestReceived = 'REQUEST_RECEIVED',
  Validating = 'VALIDATING',
  Withdrawn = 'WITHDRAWN'
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  _id: Scalars['ID'];
  chargeTakeRate: Scalars['Boolean'];
  description: Scalars['String'];
  engagementIds?: Maybe<Array<Scalars['ID']>>;
  engagements?: Maybe<Array<Engagement>>;
  isImmutable: Scalars['Boolean'];
  subItems?: Maybe<Array<InvoiceSubItem>>;
  value: Scalars['Float'];
  workedHours?: Maybe<Scalars['Float']>;
};

export type InvoiceNote = {
  __typename?: 'InvoiceNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user?: Maybe<User>;
};

export type InvoiceSort = {
  direction: SortDirection;
  field: InvoiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InvoiceSortFields {
  Id = '_id',
  BillingCode = 'billingCode',
  ChargeableValue = 'chargeableValue',
  Code = 'code',
  CompanyId = 'companyId',
  CompanyName = 'company__name',
  CreatedAt = 'createdAt',
  CycleId = 'cycleId',
  DueDate = 'dueDate',
  EngagementId = 'engagementId',
  ExternalPayment = 'externalPayment',
  FinancialEntityId = 'financialEntityId',
  IsCustomInvoice = 'isCustomInvoice',
  IsPlatformInvoice = 'isPlatformInvoice',
  LegalEntityId = 'legalEntityId',
  NotesNote = 'notes__note',
  Number = 'number',
  PeriodEnd = 'periodEnd',
  PeriodStart = 'periodStart',
  RateBrl = 'rateBRL',
  Status = 'status',
  TakeRate = 'takeRate',
  TalentUserId = 'talentUserId',
  TalentEmail = 'talent__email',
  TalentFirstName = 'talent__firstName',
  TalentFullName = 'talent__fullName',
  TalentLastName = 'talent__lastName',
  Total = 'total',
  TotalBrl = 'totalBRL'
}

export enum InvoiceStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  MissingFinancialEntity = 'MISSING_FINANCIAL_ENTITY',
  PaymentReceived = 'PAYMENT_RECEIVED',
  PendingReview = 'PENDING_REVIEW',
  TransferredToWallet = 'TRANSFERRED_TO_WALLET',
  TransferFailed = 'TRANSFER_FAILED',
  TransferInProgress = 'TRANSFER_IN_PROGRESS',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  WaitingForPaymentDelayed = 'WAITING_FOR_PAYMENT_DELAYED'
}

export type InvoiceStatusEntry = {
  __typename?: 'InvoiceStatusEntry';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type InvoiceStatusFilterComparison = {
  eq?: InputMaybe<InvoiceStatus>;
  gt?: InputMaybe<InvoiceStatus>;
  gte?: InputMaybe<InvoiceStatus>;
  iLike?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<InvoiceStatus>;
  lt?: InputMaybe<InvoiceStatus>;
  lte?: InputMaybe<InvoiceStatus>;
  neq?: InputMaybe<InvoiceStatus>;
  notILike?: InputMaybe<InvoiceStatus>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
  notLike?: InputMaybe<InvoiceStatus>;
};

export type InvoiceSubItem = {
  __typename?: 'InvoiceSubItem';
  description: Scalars['String'];
  value: Scalars['Float'];
};

export type JsonObjectFilterComparison = {
  eq?: InputMaybe<Scalars['JSONObject']>;
  gt?: InputMaybe<Scalars['JSONObject']>;
  gte?: InputMaybe<Scalars['JSONObject']>;
  iLike?: InputMaybe<Scalars['JSONObject']>;
  in?: InputMaybe<Array<Scalars['JSONObject']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['JSONObject']>;
  lt?: InputMaybe<Scalars['JSONObject']>;
  lte?: InputMaybe<Scalars['JSONObject']>;
  neq?: InputMaybe<Scalars['JSONObject']>;
  notILike?: InputMaybe<Scalars['JSONObject']>;
  notIn?: InputMaybe<Array<Scalars['JSONObject']>>;
  notLike?: InputMaybe<Scalars['JSONObject']>;
};

export type Job = {
  __typename?: 'Job';
  _id: Scalars['ID'];
  additionalInformation?: Maybe<Scalars['String']>;
  archiveReason?: Maybe<Scalars['String']>;
  /** company's incentives and perks */
  benefits?: Maybe<Scalars['String']>;
  candidateUserIds: Array<Scalars['ID']>;
  /** @deprecated You can use getAllJobCandidates to get both archived and unarchived candidates. */
  candidates: Array<JobCandidate>;
  code: Scalars['String'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  /** Contract duration in months */
  contractDurationInMonths?: Maybe<Scalars['Int']>;
  contractType?: Maybe<ContractType>;
  createdAt: Scalars['DateTime'];
  engagementManager: User;
  engagementManagerUserId: Scalars['ID'];
  /** Minimum expected salary */
  expectedSalaryFrom: Scalars['Float'];
  /** Maximum expected salary. */
  expectedSalaryTo: Scalars['Float'];
  generalInformation?: Maybe<Scalars['String']>;
  hideSalaryFromPublic: Scalars['Boolean'];
  /** must have requirements */
  mustHaveRequirements?: Maybe<Scalars['String']>;
  /** nice to have requirements */
  niceToHaveRequirements?: Maybe<Scalars['String']>;
  notArchivedCandidates: Array<JobCandidate>;
  notes?: Maybe<Array<JobNote>>;
  paymentCycle?: Maybe<PaymentCycle>;
  paymentType?: Maybe<PaymentType>;
  position: Scalars['String'];
  responsibilities?: Maybe<Scalars['String']>;
  /** Field with negotiation information, not visible to candidate */
  sellingPoints?: Maybe<Scalars['String']>;
  seniority?: Maybe<Seniority>;
  skills: Array<JobSkill>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  statusHistory: Array<JobStatusEntry>;
  updatedAt: Scalars['DateTime'];
  workloadHoursPerWeek?: Maybe<Scalars['Int']>;
  /** years amount of contract experience duration */
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type JobCandidate = {
  __typename?: 'JobCandidate';
  _id: Scalars['ID'];
  archiveReason?: Maybe<Scalars['String']>;
  assignee?: Maybe<User>;
  assigneeUserId?: Maybe<Scalars['ID']>;
  bitrixSourcingId?: Maybe<Scalars['Float']>;
  candidate?: Maybe<JobCandidateSourcingCandidate>;
  checklist?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  flagDescription?: Maybe<Scalars['String']>;
  history: Array<JobCandidateHistoryEntry>;
  job: Job;
  jobId: Scalars['ID'];
  lastStage?: Maybe<JobCandidateStage>;
  nextAction?: Maybe<JobCandidateAction>;
  sharedProfile: Scalars['Boolean'];
  sharedProfileDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<JobCandidateSource>;
  stage: JobCandidateStage;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type JobCandidateAction = {
  __typename?: 'JobCandidateAction';
  _id: Scalars['ID'];
  communicationChannel?: Maybe<JobCandidateCommunicationChannel>;
  description?: Maybe<Scalars['String']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  updatedById: Scalars['ID'];
};

export enum JobCandidateCommunicationChannel {
  Another = 'ANOTHER',
  Email = 'EMAIL',
  Slack = 'SLACK',
  Whatsapp = 'WHATSAPP'
}

export type JobCandidateConnection = {
  __typename?: 'JobCandidateConnection';
  /** Array of edges. */
  edges: Array<JobCandidateEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type JobCandidateEdge = {
  __typename?: 'JobCandidateEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the JobCandidate */
  node: JobCandidate;
};

export type JobCandidateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<JobCandidateFilter>>;
  archiveReason?: InputMaybe<StringFieldComparison>;
  assigneeUserId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  jobId?: InputMaybe<IdFilterComparison>;
  job__seniority?: InputMaybe<StringFieldComparison>;
  job__skills?: InputMaybe<JobCandidateJob__SkillsFilterComparison>;
  job__status?: InputMaybe<JobCandidateJob__StatusFilterComparison>;
  lastStage?: InputMaybe<JobCandidateStageFilterComparison>;
  or?: InputMaybe<Array<JobCandidateFilter>>;
  stage?: InputMaybe<JobCandidateStageFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  user__email?: InputMaybe<StringFieldComparison>;
  user__fullName?: InputMaybe<StringFieldComparison>;
};

export type JobCandidateHistoryEntry = {
  __typename?: 'JobCandidateHistoryEntry';
  _id: Scalars['ID'];
  archiveReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  event: JobCandidateHistoryEvent;
  stage?: Maybe<JobCandidateStage>;
  updatedAt: Scalars['DateTime'];
};

export enum JobCandidateHistoryEvent {
  FlagAdded = 'FLAG_ADDED',
  FlagRemoved = 'FLAG_REMOVED',
  StageChanged = 'STAGE_CHANGED'
}

export type JobCandidateJob__SkillsFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type JobCandidateJob__StatusFilterComparison = {
  eq?: InputMaybe<JobStatus>;
  in?: InputMaybe<Array<JobStatus>>;
};

export type JobCandidateSort = {
  direction: SortDirection;
  field: JobCandidateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JobCandidateSortFields {
  Id = '_id',
  ArchiveReason = 'archiveReason',
  AssigneeUserId = 'assigneeUserId',
  CreatedAt = 'createdAt',
  JobId = 'jobId',
  JobSeniority = 'job__seniority',
  JobSkills = 'job__skills',
  JobStatus = 'job__status',
  LastStage = 'lastStage',
  Stage = 'stage',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  UserEmail = 'user__email',
  UserFullName = 'user__fullName'
}

export enum JobCandidateSource {
  ExpressMatching = 'EXPRESS_MATCHING',
  TalentPool = 'TALENT_POOL',
  Waitlist = 'WAITLIST'
}

export type JobCandidateSourcingCandidate = {
  __typename?: 'JobCandidateSourcingCandidate';
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  linkedinHandle: Scalars['String'];
  linkedinProfile: Scalars['String'];
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['ID']>;
  profile__linkedinHandle: Scalars['String'];
  profile__linkedinProfile: Scalars['String'];
  profile__user__email: Scalars['String'];
  profile__user__fullName: Scalars['String'];
};

export type JobCandidateSourcingInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  linkedinProfile: Scalars['String'];
  profileId?: InputMaybe<Scalars['ID']>;
};

export enum JobCandidateStage {
  Archived = 'ARCHIVED',
  ContractSignature = 'CONTRACT_SIGNATURE',
  KickOffAlignments = 'KICK_OFF_ALIGNMENTS',
  Matching = 'MATCHING',
  PreMatching = 'PRE_MATCHING',
  Proposal = 'PROPOSAL',
  Sourcing = 'SOURCING',
  Vetting = 'VETTING'
}

export type JobCandidateStageFilterComparison = {
  eq?: InputMaybe<JobCandidateStage>;
  gt?: InputMaybe<JobCandidateStage>;
  gte?: InputMaybe<JobCandidateStage>;
  iLike?: InputMaybe<JobCandidateStage>;
  in?: InputMaybe<Array<JobCandidateStage>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<JobCandidateStage>;
  lt?: InputMaybe<JobCandidateStage>;
  lte?: InputMaybe<JobCandidateStage>;
  neq?: InputMaybe<JobCandidateStage>;
  notILike?: InputMaybe<JobCandidateStage>;
  notIn?: InputMaybe<Array<JobCandidateStage>>;
  notLike?: InputMaybe<JobCandidateStage>;
};

export type JobConnection = {
  __typename?: 'JobConnection';
  /** Array of edges. */
  edges: Array<JobEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type JobEdge = {
  __typename?: 'JobEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Job */
  node: Job;
};

export type JobFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<JobFilter>>;
  archiveReason?: InputMaybe<StringFieldComparison>;
  candidates__assigneeUserId?: InputMaybe<IdFilterComparison>;
  candidates__fullName?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  companyName?: InputMaybe<StringFieldComparison>;
  engagementManagerUserId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<JobFilter>>;
  position?: InputMaybe<StringFieldComparison>;
  seniority?: InputMaybe<SeniorityFilterComparison>;
  skills__name?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<JobStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type JobNote = {
  __typename?: 'JobNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user: User;
};

export type JobSkill = {
  __typename?: 'JobSkill';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  experienceYears?: Maybe<Scalars['Int']>;
  skill: Skill;
  skillId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type JobSort = {
  direction: SortDirection;
  field: JobSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JobSortFields {
  Id = '_id',
  ArchiveReason = 'archiveReason',
  CandidatesAssigneeUserId = 'candidates__assigneeUserId',
  CandidatesFullName = 'candidates__fullName',
  Code = 'code',
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  EngagementManagerUserId = 'engagementManagerUserId',
  Position = 'position',
  Seniority = 'seniority',
  SkillsName = 'skills__name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum JobStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  PendingCandidates = 'PENDING_CANDIDATES',
  Recruiting = 'RECRUITING'
}

export type JobStatusEntry = {
  __typename?: 'JobStatusEntry';
  _id: Scalars['ID'];
  archiveReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  status: JobStatus;
  updatedAt: Scalars['DateTime'];
};

export type JobStatusFilterComparison = {
  eq?: InputMaybe<JobStatus>;
  gt?: InputMaybe<JobStatus>;
  gte?: InputMaybe<JobStatus>;
  iLike?: InputMaybe<JobStatus>;
  in?: InputMaybe<Array<JobStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<JobStatus>;
  lt?: InputMaybe<JobStatus>;
  lte?: InputMaybe<JobStatus>;
  neq?: InputMaybe<JobStatus>;
  notILike?: InputMaybe<JobStatus>;
  notIn?: InputMaybe<Array<JobStatus>>;
  notLike?: InputMaybe<JobStatus>;
};

export enum LearningCategory {
  All = 'ALL',
  Audiobook = 'AUDIOBOOK',
  Book = 'BOOK',
  Course = 'COURSE',
  Interactive = 'INTERACTIVE',
  LearningPath = 'LEARNING_PATH',
  LiveEvents = 'LIVE_EVENTS',
  Playlist = 'PLAYLIST',
  PracticeExams = 'PRACTICE_EXAMS',
  Video = 'VIDEO'
}

export type LearningContent = {
  __typename?: 'LearningContent';
  next?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  previous?: Maybe<Scalars['Int']>;
  results: Array<ContentResult>;
  total: Scalars['Int'];
};

export type LearningContentInitialResults = {
  __typename?: 'LearningContentInitialResults';
  results: Array<ContentResult>;
};

export enum LearningContentType {
  Article = 'ARTICLE',
  Audiobook = 'AUDIOBOOK',
  Book = 'BOOK',
  CaseStudy = 'CASE_STUDY',
  Course = 'COURSE',
  LearningPath = 'LEARNING_PATH',
  LiveEvent = 'LIVE_EVENT',
  Playlist = 'PLAYLIST',
  PracticeExam = 'PRACTICE_EXAM',
  Sandbox = 'SANDBOX',
  Scenario = 'SCENARIO',
  Video = 'VIDEO'
}

export type LearningSearchInput = {
  category: LearningCategory;
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  _id: Scalars['ID'];
  /** @deprecated Use street, streetNumber and neighborhood instead. */
  address?: Maybe<Scalars['String']>;
  addressComplement?: Maybe<Scalars['String']>;
  /** @deprecated Use billingContacts. */
  billingContact?: Maybe<BillingContact>;
  billingContacts: Array<LegalEntityBillingContact>;
  city: Scalars['String'];
  company: Company;
  companyId: Scalars['ID'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  employeeIdentificationNumber: Scalars['String'];
  name: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LegalEntityBillingContact = {
  __typename?: 'LegalEntityBillingContact';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use firstName and lastName instead. */
  name?: Maybe<Scalars['String']>;
};

export type LegalEntityConnection = {
  __typename?: 'LegalEntityConnection';
  /** Array of edges. */
  edges: Array<LegalEntityEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type LegalEntityEdge = {
  __typename?: 'LegalEntityEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the LegalEntity */
  node: LegalEntity;
};

export type LegalEntityFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<LegalEntityFilter>>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<LegalEntityFilter>>;
};

export type LegalEntitySort = {
  direction: SortDirection;
  field: LegalEntitySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LegalEntitySortFields {
  Id = '_id',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt'
}

export type LinkedinInput = {
  certifications: Scalars['Boolean'];
  education: Scalars['Boolean'];
  summary: Scalars['Boolean'];
  workExperience: Scalars['Boolean'];
};

export enum Locale {
  En = 'EN',
  PtBr = 'PT_BR'
}

export type LocalizationAttributesData = {
  __typename?: 'LocalizationAttributesData';
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  slug?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  deviceId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  shouldKeepLogged?: InputMaybe<Scalars['Boolean']>;
};

export type MatchingProfile = {
  __typename?: 'MatchingProfile';
  _id: Scalars['ID'];
  mainSkills: Array<PublicProfileSkill>;
  matchSkillSize: Scalars['Int'];
  skills: Array<PublicProfileSkill>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type Media = {
  __typename?: 'Media';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Formats>;
  hash?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaFormat = {
  __typename?: 'MediaFormat';
  ext?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type MetaSocial = {
  __typename?: 'MetaSocial';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Media>;
  title?: Maybe<Scalars['String']>;
};

export type MoodPulseInput = {
  comment: Scalars['String'];
  engagementId: Scalars['ID'];
  score: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCookiePolicy: Scalars['Boolean'];
  acceptUserCompanyInvitation: User;
  addAdditionalSkillsToMyProfile: Profile;
  addCandidateToSourcing: JobCandidate;
  addCandidateToSourcingDeprecated: Sourcing;
  addCandidatesToJob: Job;
  addCertificationToMyProfile: Profile;
  addCodeSampleToMyProfile: Profile;
  addEducationToMyProfile: Profile;
  addEngagementUserToCompany: Company;
  addEvaluationToBlogGuide: GuideAttributeData;
  addEvaluationToBlogPost: PostAttributeData;
  addFinancialEntityInfoForMe: FinancialEntity;
  addFinancialEntityInfoForTalent: FinancialEntity;
  addJobCandidateFlag: JobCandidate;
  addMainSkillToMyProfile: Profile;
  addNoteToBilling: Billing;
  addNoteToCompany: Company;
  addNoteToEngagement: Engagement;
  addNoteToInvoice: Invoice;
  addNoteToJob: Job;
  addNoteToProfile: Profile;
  addUserToCompany: Company;
  addUserToGroup?: Maybe<User>;
  addWorkExperienceToMyProfile: Profile;
  authorizeRdStation: Scalars['Boolean'];
  /** @deprecated Let's use createBackofficeUser */
  backofficeSignUp: User;
  bulkUpdateAdditionalSkillsInMyProfile: Profile;
  bulkUpdateMainSkillsInMyProfile: Profile;
  changePassword: PasswordUpdateOutput;
  cleanAllProfileReviewsFromDb: Scalars['Boolean'];
  /** This method creates a company and a company user with a random password */
  companyUserSignUp: User;
  confirmForgotPassword: PasswordUpdateOutput;
  confirmSignUp: Scalars['Boolean'];
  createBackofficeUser: User;
  createBilling: Billing;
  createBillingCycle: BillingCycle;
  createCompany: Company;
  createCustomInvoice: Invoice;
  createEngagement: Engagement;
  createEngagementBillingCycles: Array<BillingCycle>;
  createFinancialEntityForMe: FinancialEntity;
  createFinancialEntityForTalent: FinancialEntity;
  createJob: Job;
  createLegalEntity: LegalEntity;
  createSkill: Skill;
  createTalentInvoice: Invoice;
  createTerm: Term;
  createWorklog: Worklog;
  deleteBillingCycle: BillingCycle;
  deleteCompanyUserInvitation: CompanyUserInvitation;
  deleteEngagement: Engagement;
  deleteFinancialEntity: FinancialEntity;
  deleteJob: Job;
  deleteWorklog: Worklog;
  /** @deprecated Use dispatchTalentInvoiceCreationForBillingCycle instead. */
  dispatchInvoiceCreationForBillingCycle: Scalars['Boolean'];
  dispatchTalentInvoiceCreationForBillingCycle: Scalars['Boolean'];
  forceCheckClosingBillingCycles: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  importLinkedinData: Profile;
  initiateHuskyPayment: Invoice;
  inviteCompanyUser: CompanyUserInvitation;
  login: User;
  loginCMS: User;
  logout: Scalars['Boolean'];
  migrateJobCandidateFromSourcing: Scalars['Boolean'];
  migrateProfileReviewsToBitrix: Scalars['Boolean'];
  migrateProfilesToBitrix: Scalars['Boolean'];
  onboardingStepFour: Profile;
  onboardingStepOne: Profile;
  onboardingStepThree: Profile;
  onboardingStepTwo: Profile;
  refreshHistoricalPlaidTransactions: Scalars['Boolean'];
  removeAdditionalSkillFromMyProfile: Profile;
  removeCandidateFromJob: Job;
  removeCertificationFromMyProfile: Profile;
  removeCodeSampleFromMyProfile: Profile;
  removeEducationFromMyProfile: Profile;
  removeEngagementUserFromCompany: Company;
  removeJobCandidateFlag: JobCandidate;
  removeMainSkillFromMyProfile: Profile;
  removeNotesFromBilling: Billing;
  removeNotesFromCompany: Company;
  removeNotesFromEngagement: Engagement;
  removeNotesFromInvoice: Invoice;
  removeNotesFromJob: Job;
  removeNotesFromProfile: Profile;
  removeTalent: Scalars['Boolean'];
  removeUserFromCompany: Company;
  removeUserFromGroup?: Maybe<User>;
  removeWorkExperienceFromMyProfile: Profile;
  requestCompanyPlatformService: Scalars['Boolean'];
  resendConfirmation: Scalars['String'];
  sendBillingEmails: Scalars['Boolean'];
  sendMoodPulse: IMoodPulse;
  /** @deprecated Let's use "updateUser" mutation to update the "groups" field */
  setUserGroups?: Maybe<User>;
  signUp: User;
  startEngagementByIdAndStatus: Scalars['Boolean'];
  startProfileVettingProcess: Scalars['Boolean'];
  subscribeToTalentNewsletter: Scalars['Boolean'];
  updateAdditionalSkillInMyProfile: Profile;
  updateAllCurrentEngagementsOnAmplitude: Scalars['Boolean'];
  updateAllProfilesOnAmplitude: Scalars['Boolean'];
  updateBilling: Billing;
  updateBillingCycle: BillingCycle;
  updateBillingInvoiceStatus: Billing;
  updateBillingInvoicesZip: Scalars['Boolean'];
  updateBlogSitemap: Scalars['Boolean'];
  updateCertificationInMyProfile: Profile;
  updateCodeSampleInMyProfile: Profile;
  updateCompany: Company;
  updateEducationInMyProfile: Profile;
  updateEngagement: Engagement;
  updateFinancialEntity: FinancialEntity;
  /** @deprecated Use updateTalentInvoicePdfUrl instead. */
  updateInvoicePdfUrl: Invoice;
  updateJob: Job;
  updateJobCandidate: JobCandidate;
  updateJobCandidateStage: JobCandidate;
  updateJobStatus: Job;
  updateLegalEntity: LegalEntity;
  updateMainSkillInMyProfile: Profile;
  updateMe?: Maybe<User>;
  updateMyProfile: Profile;
  updateOvertimeJustification: BillingCycle;
  /** @deprecated Use `updateMyProfile` instead. */
  updatePicture: Profile;
  updatePlatformInvoice: Invoice;
  updatePlatformInvoicePdfUrl: Invoice;
  updateProfile: Profile;
  updateProfileMainSkill: Profile;
  updateSkill: Skill;
  updateTalentAvailability: Profile;
  updateTalentInvoice: Invoice;
  updateTalentInvoicePdfUrl: Invoice;
  updateTerm: Term;
  updateUser?: Maybe<User>;
  updateUserAttributes: User;
  updateUserStatus: User;
  updateVettingStatusByUserId?: Maybe<Profile>;
  updateWorkExperienceInMyProfile: Profile;
  updateWorklog: Worklog;
  validateEmail: User;
};


export type MutationAcceptUserCompanyInvitationArgs = {
  input: CompanyInvitedUserSignUpInput;
};


export type MutationAddAdditionalSkillsToMyProfileArgs = {
  additionalSkills: Array<ProfileSkillInput>;
};


export type MutationAddCandidateToSourcingArgs = {
  input: AddJobCandidateSourcingInput;
};


export type MutationAddCandidateToSourcingDeprecatedArgs = {
  input: AddCandidateInput;
};


export type MutationAddCandidatesToJobArgs = {
  input: AddJobCandidateInput;
};


export type MutationAddCertificationToMyProfileArgs = {
  certification: CertificationInput;
};


export type MutationAddCodeSampleToMyProfileArgs = {
  input: ProfileCodeSampleInput;
};


export type MutationAddEducationToMyProfileArgs = {
  education: EducationInput;
};


export type MutationAddEngagementUserToCompanyArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddEvaluationToBlogGuideArgs = {
  id: Scalars['String'];
  input: EvaluationInput;
};


export type MutationAddEvaluationToBlogPostArgs = {
  id: Scalars['String'];
  input: EvaluationInput;
};


export type MutationAddFinancialEntityInfoForMeArgs = {
  input: UpdateFinancialEntityInput;
};


export type MutationAddFinancialEntityInfoForTalentArgs = {
  input: UpdateFinancialEntityInput;
  talentUserId: Scalars['ID'];
};


export type MutationAddJobCandidateFlagArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAddMainSkillToMyProfileArgs = {
  mainSkill: ProfileSkillInput;
};


export type MutationAddNoteToBillingArgs = {
  billingId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddNoteToCompanyArgs = {
  companyId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddNoteToEngagementArgs = {
  engagementId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddNoteToInvoiceArgs = {
  invoiceId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddNoteToJobArgs = {
  id: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddNoteToProfileArgs = {
  note: Scalars['String'];
  profileId: Scalars['ID'];
};


export type MutationAddUserToCompanyArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddUserToGroupArgs = {
  input: UpdateUserGroupsInput;
};


export type MutationAddWorkExperienceToMyProfileArgs = {
  workExperience: AddWorkExperienceInput;
};


export type MutationAuthorizeRdStationArgs = {
  code: Scalars['String'];
};


export type MutationBackofficeSignUpArgs = {
  input: CreateBackofficeUserInput;
};


export type MutationBulkUpdateAdditionalSkillsInMyProfileArgs = {
  input: BulkUpdateProfileSkillsInput;
};


export type MutationBulkUpdateMainSkillsInMyProfileArgs = {
  input: BulkUpdateProfileSkillsInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCompanyUserSignUpArgs = {
  input: CompanyUserSignUpInput;
};


export type MutationConfirmForgotPasswordArgs = {
  input: ConfirmForgotPasswordInput;
};


export type MutationConfirmSignUpArgs = {
  email: Scalars['String'];
};


export type MutationCreateBackofficeUserArgs = {
  input: CreateBackofficeUserInput;
};


export type MutationCreateBillingArgs = {
  customPlatformInvoiceId?: InputMaybe<Scalars['ID']>;
  talentInvoiceIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingCycleArgs = {
  input: CreateBillingCycleInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCustomInvoiceArgs = {
  input: CreateCustomInvoiceInput;
};


export type MutationCreateEngagementArgs = {
  input: CreateEngagementInput;
};


export type MutationCreateEngagementBillingCyclesArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFinancialEntityForMeArgs = {
  input: CreateFinancialEntityInput;
};


export type MutationCreateFinancialEntityForTalentArgs = {
  input: CreateFinancialEntityInput;
  talentUserId: Scalars['ID'];
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateLegalEntityArgs = {
  input: CreateLegalEntityInput;
};


export type MutationCreateSkillArgs = {
  input: CreateSkillInput;
};


export type MutationCreateTalentInvoiceArgs = {
  input: CreateTalentInvoiceInput;
};


export type MutationCreateTermArgs = {
  input: CreateTermInput;
};


export type MutationCreateWorklogArgs = {
  input: CreateWorklogInput;
};


export type MutationDeleteBillingCycleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyUserInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationDeleteEngagementArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFinancialEntityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorklogArgs = {
  id: Scalars['ID'];
};


export type MutationDispatchInvoiceCreationForBillingCycleArgs = {
  billingCycleId: Scalars['ID'];
};


export type MutationDispatchTalentInvoiceCreationForBillingCycleArgs = {
  billingCycleId: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationImportLinkedinDataArgs = {
  input: LinkedinInput;
};


export type MutationInitiateHuskyPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationInviteCompanyUserArgs = {
  input: CompanyInvitationInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginCmsArgs = {
  input: LoginInput;
};


export type MutationOnboardingStepFourArgs = {
  input: OnboardingStepFourInput;
};


export type MutationOnboardingStepOneArgs = {
  input: OnboardingStepOneInput;
};


export type MutationOnboardingStepThreeArgs = {
  input: OnboardingStepThreeInput;
};


export type MutationOnboardingStepTwoArgs = {
  input: OnboardingStepTwoInput;
};


export type MutationRefreshHistoricalPlaidTransactionsArgs = {
  plaidItemId: Scalars['String'];
};


export type MutationRemoveAdditionalSkillFromMyProfileArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCandidateFromJobArgs = {
  input: RemoveJobCandidateInput;
};


export type MutationRemoveCertificationFromMyProfileArgs = {
  certificationId: Scalars['ID'];
};


export type MutationRemoveCodeSampleFromMyProfileArgs = {
  codeSampleId: Scalars['ID'];
};


export type MutationRemoveEducationFromMyProfileArgs = {
  educationId: Scalars['ID'];
};


export type MutationRemoveEngagementUserFromCompanyArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveJobCandidateFlagArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMainSkillFromMyProfileArgs = {
  id: Scalars['ID'];
  removeOption: RemoveMainSkill;
};


export type MutationRemoveNotesFromBillingArgs = {
  billingId: Scalars['ID'];
  noteIds: Array<Scalars['ID']>;
};


export type MutationRemoveNotesFromCompanyArgs = {
  companyId: Scalars['ID'];
  noteIds: Array<Scalars['ID']>;
};


export type MutationRemoveNotesFromEngagementArgs = {
  engagementId: Scalars['ID'];
  noteIds: Array<Scalars['ID']>;
};


export type MutationRemoveNotesFromInvoiceArgs = {
  invoiceId: Scalars['ID'];
  noteIds: Array<Scalars['ID']>;
};


export type MutationRemoveNotesFromJobArgs = {
  id: Scalars['ID'];
  noteIds: Array<Scalars['ID']>;
};


export type MutationRemoveNotesFromProfileArgs = {
  noteIds: Array<Scalars['ID']>;
  profileId: Scalars['ID'];
};


export type MutationRemoveTalentArgs = {
  profileId: Scalars['ID'];
};


export type MutationRemoveUserFromCompanyArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveUserFromGroupArgs = {
  input: UpdateUserGroupsInput;
};


export type MutationRemoveWorkExperienceFromMyProfileArgs = {
  workExperienceId: Scalars['ID'];
};


export type MutationRequestCompanyPlatformServiceArgs = {
  platformService: CompanyPlatformService;
};


export type MutationResendConfirmationArgs = {
  email: Scalars['String'];
};


export type MutationSendBillingEmailsArgs = {
  billingId: Scalars['ID'];
};


export type MutationSendMoodPulseArgs = {
  moodPulse: MoodPulseInput;
};


export type MutationSetUserGroupsArgs = {
  input: SetUserGroupsInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationStartEngagementByIdAndStatusArgs = {
  engagementId: Scalars['ID'];
  status: EngagementStatus;
};


export type MutationStartProfileVettingProcessArgs = {
  profileId: Scalars['ID'];
};


export type MutationSubscribeToTalentNewsletterArgs = {
  email: Scalars['String'];
};


export type MutationUpdateAdditionalSkillInMyProfileArgs = {
  input: UpdateProfileSkillInput;
};


export type MutationUpdateBillingArgs = {
  id: Scalars['ID'];
  input: UpdateBillingInput;
};


export type MutationUpdateBillingCycleArgs = {
  id: Scalars['ID'];
  input: UpdateBillingCycleInput;
};


export type MutationUpdateBillingInvoiceStatusArgs = {
  billingId: Scalars['ID'];
  input: UpdateBillingInvoiceStatusInput;
  invoiceId: Scalars['ID'];
};


export type MutationUpdateBillingInvoicesZipArgs = {
  billingId: Scalars['ID'];
};


export type MutationUpdateCertificationInMyProfileArgs = {
  certification: UpdateCertificationInput;
};


export type MutationUpdateCodeSampleInMyProfileArgs = {
  codeSample: UpdateByIdProfileCodeSampleInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: UpdateCompanyInput;
};


export type MutationUpdateEducationInMyProfileArgs = {
  education: UpdateEducationInput;
};


export type MutationUpdateEngagementArgs = {
  id: Scalars['ID'];
  input: UpdateEngagementInput;
};


export type MutationUpdateFinancialEntityArgs = {
  id: Scalars['ID'];
  input: UpdateFinancialEntityInput;
};


export type MutationUpdateInvoicePdfUrlArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID'];
  input: UpdateJobInput;
};


export type MutationUpdateJobCandidateArgs = {
  id: Scalars['ID'];
  input: UpdateJobCandidateInput;
};


export type MutationUpdateJobCandidateStageArgs = {
  id: Scalars['ID'];
  input: UpdateJobCandidateStageInput;
};


export type MutationUpdateJobStatusArgs = {
  id: Scalars['ID'];
  input: UpdateJobStatusInput;
};


export type MutationUpdateLegalEntityArgs = {
  id: Scalars['ID'];
  input: UpdateLegalEntityInput;
};


export type MutationUpdateMainSkillInMyProfileArgs = {
  input: UpdateProfileSkillInput;
};


export type MutationUpdateMeArgs = {
  input: PartialUpdateByUserInput;
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateOvertimeJustificationArgs = {
  id: Scalars['ID'];
  justification: Scalars['String'];
};


export type MutationUpdatePictureArgs = {
  key: Scalars['String'];
};


export type MutationUpdatePlatformInvoiceArgs = {
  id: Scalars['ID'];
  input: UpdatePlatformInvoiceInput;
};


export type MutationUpdatePlatformInvoicePdfUrlArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationUpdateProfileArgs = {
  input: BackofficeUpdateProfileInput;
  profileId: Scalars['ID'];
};


export type MutationUpdateProfileMainSkillArgs = {
  mainSkill: UpdateByIdProfileSkillInputBackoffice;
  profileId: Scalars['ID'];
};


export type MutationUpdateSkillArgs = {
  id: Scalars['ID'];
  input: UpdateSkillInput;
};


export type MutationUpdateTalentAvailabilityArgs = {
  input: BackofficeUpdateAvailabilityInput;
  profileId: Scalars['ID'];
};


export type MutationUpdateTalentInvoiceArgs = {
  id: Scalars['ID'];
  input: UpdateTalentInvoiceInput;
};


export type MutationUpdateTalentInvoicePdfUrlArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationUpdateTermArgs = {
  id: Scalars['ID'];
  input: UpdateTermInput;
};


export type MutationUpdateUserArgs = {
  input: PartialUpdateInput;
  userId: Scalars['ID'];
};


export type MutationUpdateUserAttributesArgs = {
  email: Scalars['String'];
  input: UpdateUserAttributes;
};


export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatus;
};


export type MutationUpdateVettingStatusByUserIdArgs = {
  userId: Scalars['ID'];
  vettingStatus: VettingStatus;
};


export type MutationUpdateWorkExperienceInMyProfileArgs = {
  workExperience: UpdateWorkExperienceInput;
};


export type MutationUpdateWorklogArgs = {
  id: Scalars['ID'];
  input: UpdateWorklogInput;
};


export type MutationValidateEmailArgs = {
  code: Scalars['String'];
  deviceId?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type NumberFiltersPartial = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type OffsetPaging = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OnboardingStepFourInput = {
  linkedinProfile: Scalars['String'];
  location: Scalars['String'];
  referral?: InputMaybe<ProfileReferralInput>;
};

export type OnboardingStepOneInput = {
  preferredRoles: Array<ProfilePreferredRole>;
  versionMetadata: ProfileVersionMetadataInput;
  yearsOfExperience: Scalars['Int'];
};

export type OnboardingStepThreeInput = {
  englishProficiency: EnglishProficiency;
};

export type OnboardingStepTwoInput = {
  skillId: Scalars['ID'];
  yearsOfExperience: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginationArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PartialCertificationInput = {
  authority?: InputMaybe<Scalars['String']>;
  displaySource?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PartialEducationInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  fieldOfStudy?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type PartialUpdateByUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  pictureKey?: InputMaybe<Scalars['String']>;
  slackHandle?: InputMaybe<Scalars['String']>;
  slackUserId?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<UserTheme>;
};

export type PartialUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  canUseLearning?: InputMaybe<Scalars['Boolean']>;
  cognitoUserSub?: InputMaybe<Scalars['String']>;
  confirmationEmailLastRequested?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<Group>>;
  lastName?: InputMaybe<Scalars['String']>;
  pictureKey?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  slackHandle?: InputMaybe<Scalars['String']>;
  slackUserId?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<UserTheme>;
  verifiedEmail?: InputMaybe<Scalars['Boolean']>;
};

export type PartialWorkExperienceInput = {
  company?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  ongoing?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PasswordUpdateOutput = {
  __typename?: 'PasswordUpdateOutput';
  passwordUpdatedAt: Scalars['DateTime'];
};

export enum PaymentCycle {
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY'
}

export type PaymentCycleFilterComparison = {
  eq?: InputMaybe<PaymentCycle>;
  gt?: InputMaybe<PaymentCycle>;
  gte?: InputMaybe<PaymentCycle>;
  iLike?: InputMaybe<PaymentCycle>;
  in?: InputMaybe<Array<PaymentCycle>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<PaymentCycle>;
  lt?: InputMaybe<PaymentCycle>;
  lte?: InputMaybe<PaymentCycle>;
  neq?: InputMaybe<PaymentCycle>;
  notILike?: InputMaybe<PaymentCycle>;
  notIn?: InputMaybe<Array<PaymentCycle>>;
  notLike?: InputMaybe<PaymentCycle>;
};

export type PaymentPlatformUser = {
  __typename?: 'PaymentPlatformUser';
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export enum PaymentType {
  FlatFee = 'FLAT_FEE',
  Flexible = 'FLEXIBLE'
}

export type PaymentTypeFilterComparison = {
  eq?: InputMaybe<PaymentType>;
  gt?: InputMaybe<PaymentType>;
  gte?: InputMaybe<PaymentType>;
  iLike?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<PaymentType>;
  lt?: InputMaybe<PaymentType>;
  lte?: InputMaybe<PaymentType>;
  neq?: InputMaybe<PaymentType>;
  notILike?: InputMaybe<PaymentType>;
  notIn?: InputMaybe<Array<PaymentType>>;
  notLike?: InputMaybe<PaymentType>;
};

export type PendingBillingsAndBankInfo = {
  __typename?: 'PendingBillingsAndBankInfo';
  billings: Array<Billing>;
  platformBankInfo: PlatformBankInfo;
  totalDueByStatus: BillingsTotalDueByStatus;
};

export type PlatformBankInfo = {
  __typename?: 'PlatformBankInfo';
  abaRoutingTransit: Scalars['String'];
  creditOf: CreditOfEntity;
  identifier: BankIdentifier;
};

export type PlatformInvoiceItemInput = {
  _id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  engagementIds?: InputMaybe<Array<Scalars['ID']>>;
  value?: InputMaybe<Scalars['Float']>;
};

export type PostAttributeData = {
  __typename?: 'PostAttributeData';
  data?: Maybe<FullPostAttributes>;
};

export type PostAttributesData = {
  __typename?: 'PostAttributesData';
  data?: Maybe<Array<FullPostAttributes>>;
  pagination?: Maybe<Pagination>;
};

export type PostFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<PostFilterPartial>>;
  article?: InputMaybe<StringFiltersPartial>;
  authors?: InputMaybe<AuthorFiltersPartial>;
  category?: InputMaybe<CategoryFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  helpfulScore?: InputMaybe<NumberFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  notHelpfulScore?: InputMaybe<NumberFiltersPartial>;
  or?: InputMaybe<Array<PostFilterPartial>>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  readingTime?: InputMaybe<NumberFiltersPartial>;
  resume?: InputMaybe<StringFiltersPartial>;
  slug?: InputMaybe<StringFiltersPartial>;
  tags?: InputMaybe<TagsFiltersPartial>;
  title?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type PostFilterPartial = {
  article?: InputMaybe<StringFiltersPartial>;
  authors?: InputMaybe<AuthorFiltersPartial>;
  category?: InputMaybe<CategoryFiltersPartial>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  helpfulScore?: InputMaybe<NumberFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  notHelpfulScore?: InputMaybe<NumberFiltersPartial>;
  publishedAt?: InputMaybe<DateFiltersPartial>;
  readingTime?: InputMaybe<NumberFiltersPartial>;
  resume?: InputMaybe<StringFiltersPartial>;
  slug?: InputMaybe<StringFiltersPartial>;
  tags?: InputMaybe<TagsFiltersPartial>;
  title?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type PostSortArgsPartial = {
  article?: InputMaybe<SortingDirection>;
  createdAt?: InputMaybe<SortingDirection>;
  helpfulScore?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  notHelpfulScore?: InputMaybe<SortingDirection>;
  publishedAt?: InputMaybe<SortingDirection>;
  readingTime?: InputMaybe<SortingDirection>;
  resume?: InputMaybe<SortingDirection>;
  slug?: InputMaybe<SortingDirection>;
  title?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export type PresignPostFields = {
  __typename?: 'PresignPostFields';
  Policy?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  xAmzAlgorithm?: Maybe<Scalars['String']>;
  xAmzCredential?: Maybe<Scalars['String']>;
  xAmzDate?: Maybe<Scalars['String']>;
  xAmzSignature?: Maybe<Scalars['String']>;
};

export type PresignPostUrlReturn = {
  __typename?: 'PresignPostUrlReturn';
  fields?: Maybe<PresignPostFields>;
  url?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['ID'];
  availability?: Maybe<Availability>;
  careerPath?: Maybe<CareerPath>;
  certifications: Array<Certification>;
  codeSamples: Array<ProfileCodeSample>;
  createdAt: Scalars['DateTime'];
  declinedAt?: Maybe<Scalars['DateTime']>;
  declinedFeedback?: Maybe<Scalars['String']>;
  education: Array<Education>;
  englishProficiency?: Maybe<EnglishProficiency>;
  englishScore?: Maybe<Scalars['Float']>;
  /**
   * Hourly rate
   * @deprecated Not useful in v2 schema
   */
  expectedSalary?: Maybe<Scalars['Float']>;
  /** Minimum expected salary */
  expectedSalaryFrom?: Maybe<Scalars['Float']>;
  /**
   * Maximum expected salary.
   * @deprecated Not useful for users and might harm metrics
   */
  expectedSalaryTo?: Maybe<Scalars['Float']>;
  /** Only numbers from 0 to 100 */
  fillingPercentage?: Maybe<Scalars['Int']>;
  financialEntity?: Maybe<FinancialEntity>;
  firstTimeFilledAt?: Maybe<Scalars['DateTime']>;
  githubProfile?: Maybe<Scalars['String']>;
  initialMatchingPrecheck?: Maybe<Scalars['Boolean']>;
  lastReviewDate?: Maybe<Scalars['DateTime']>;
  linkedinDataImportedAt?: Maybe<Scalars['DateTime']>;
  linkedinHandle?: Maybe<Scalars['String']>;
  linkedinImportBlockedUntil?: Maybe<Scalars['DateTime']>;
  linkedinProfile?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mainSkills: Array<ProfileSkill>;
  notes?: Maybe<Array<ProfileNote>>;
  onboardingStep: ProfileOnboardingStep;
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use pictureUrl instead */
  picture?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  platformMessage?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  preferredRoles: Array<ProfilePreferredRole>;
  referral?: Maybe<ProfileReferral>;
  referralCode?: Maybe<Scalars['String']>;
  referredByUser?: Maybe<User>;
  referredByUserId?: Maybe<Scalars['ID']>;
  resume?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<ProfileReviewStatus>;
  shippingAddress?: Maybe<ShippingAddress>;
  skills: Array<ProfileSkill>;
  softSkillsScore?: Maybe<Scalars['JSONObject']>;
  status?: Maybe<ProfileStatus>;
  story?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
  versionMetadata?: Maybe<ProfileVersionMetadata>;
  vettingFinishedAt?: Maybe<Scalars['DateTime']>;
  vettingStartedAt?: Maybe<Scalars['DateTime']>;
  vettingStatus: VettingStatus;
  vettingStatusHistory: Array<VettingStatusEntry>;
  welcomeViewed: Scalars['Boolean'];
  workExperience: Array<WorkExperience>;
  workableId?: Maybe<Scalars['String']>;
  workableRecruiterName?: Maybe<Scalars['String']>;
  workableStage?: Maybe<Scalars['String']>;
  workableStageHistory: Array<WorkableStageEntry>;
  workableUrl?: Maybe<Scalars['String']>;
  workingExperience?: Maybe<WorkingExperience>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type ProfileAvailabilityFilterComparison = {
  eq?: InputMaybe<Availability>;
  in?: InputMaybe<Array<Availability>>;
  neq?: InputMaybe<Availability>;
  notIn?: InputMaybe<Array<Availability>>;
};

export type ProfileCodeSample = {
  __typename?: 'ProfileCodeSample';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  title: Scalars['String'];
};

export type ProfileCodeSampleInput = {
  description?: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
  title: Scalars['String'];
};

export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** Array of edges. */
  edges: Array<ProfileEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Profile */
  node: Profile;
};

export type ProfileEnglishProficiencyFilterComparison = {
  eq?: InputMaybe<EnglishProficiency>;
  in?: InputMaybe<Array<EnglishProficiency>>;
  neq?: InputMaybe<EnglishProficiency>;
  notIn?: InputMaybe<Array<EnglishProficiency>>;
};

export type ProfileFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<ProfileFilter>>;
  availability?: InputMaybe<ProfileAvailabilityFilterComparison>;
  englishProficiency?: InputMaybe<ProfileEnglishProficiencyFilterComparison>;
  englishScore?: InputMaybe<FloatFieldComparison>;
  expectedSalaryFrom?: InputMaybe<NumberFieldComparison>;
  expectedSalaryTo?: InputMaybe<NumberFieldComparison>;
  fillingPercentage?: InputMaybe<IntFieldComparison>;
  linkedinHandle?: InputMaybe<StringFieldComparison>;
  mainSkills__experience?: InputMaybe<ProfileMainSkills__ExperienceFilterComparison>;
  mainSkills__skillId?: InputMaybe<ProfileMainSkills__SkillIdFilterComparison>;
  notes__note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ProfileFilter>>;
  referralCode?: InputMaybe<StringFieldComparison>;
  referredByUserId?: InputMaybe<IdFilterComparison>;
  reviewStatus?: InputMaybe<ProfileReviewStatusFilterComparison>;
  skills__skillId?: InputMaybe<ProfileSkills__SkillIdFilterComparison>;
  softSkillsScore?: InputMaybe<JsonObjectFilterComparison>;
  status?: InputMaybe<ProfileStatusFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
  user__active?: InputMaybe<BooleanFieldComparison>;
  user__createdAt?: InputMaybe<DateFieldComparison>;
  user__email?: InputMaybe<StringFieldComparison>;
  user__firstName?: InputMaybe<StringFieldComparison>;
  user__fullName?: InputMaybe<StringFieldComparison>;
  user__lastName?: InputMaybe<StringFieldComparison>;
  vettingFinishedAt?: InputMaybe<DateFieldComparison>;
  vettingStatus?: InputMaybe<ProfileVettingStatusFilterComparison>;
  workableId?: InputMaybe<StringFieldComparison>;
  workableRecruiterName?: InputMaybe<StringFieldComparison>;
  workableStage?: InputMaybe<StringFieldComparison>;
  workableUrl?: InputMaybe<StringFieldComparison>;
  yearsOfExperience?: InputMaybe<IntFieldComparison>;
};

export type ProfileMainSkills__ExperienceFilterComparison = {
  eq?: InputMaybe<SkillExperience>;
  in?: InputMaybe<Array<SkillExperience>>;
  neq?: InputMaybe<SkillExperience>;
  notIn?: InputMaybe<Array<SkillExperience>>;
};

export type ProfileMainSkills__SkillIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  neq?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProfileNote = {
  __typename?: 'ProfileNote';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  note: Scalars['String'];
  user?: Maybe<User>;
};

export enum ProfileOnboardingStep {
  Complete = 'COMPLETE',
  Step_1 = 'STEP_1',
  Step_2 = 'STEP_2',
  Step_3 = 'STEP_3',
  Step_4 = 'STEP_4'
}

export enum ProfilePreferredRole {
  Backend = 'BACKEND',
  Design = 'DESIGN',
  Devops = 'DEVOPS',
  Frontend = 'FRONTEND',
  Fullstack = 'FULLSTACK',
  Management = 'MANAGEMENT',
  Mobile = 'MOBILE',
  QaEngineer = 'QA_ENGINEER'
}

export type ProfileReferral = {
  __typename?: 'ProfileReferral';
  comment?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  type: ReferralType;
};

export type ProfileReferralInput = {
  comment?: InputMaybe<Scalars['String']>;
  referredBy?: InputMaybe<Scalars['String']>;
  type: ReferralType;
};

export enum ProfileReviewStatus {
  Approved = 'APPROVED',
  Incomplete = 'INCOMPLETE',
  PendingOlbyReview = 'PENDING_OLBY_REVIEW',
  PendingReport = 'PENDING_REPORT',
  WaitingTalentReview = 'WAITING_TALENT_REVIEW'
}

export type ProfileReviewStatusFilterComparison = {
  eq?: InputMaybe<ProfileReviewStatus>;
  gt?: InputMaybe<ProfileReviewStatus>;
  gte?: InputMaybe<ProfileReviewStatus>;
  iLike?: InputMaybe<ProfileReviewStatus>;
  in?: InputMaybe<Array<ProfileReviewStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ProfileReviewStatus>;
  lt?: InputMaybe<ProfileReviewStatus>;
  lte?: InputMaybe<ProfileReviewStatus>;
  neq?: InputMaybe<ProfileReviewStatus>;
  notILike?: InputMaybe<ProfileReviewStatus>;
  notIn?: InputMaybe<Array<ProfileReviewStatus>>;
  notLike?: InputMaybe<ProfileReviewStatus>;
};

export type ProfileSkill = {
  __typename?: 'ProfileSkill';
  _id: Scalars['ID'];
  assessmentFinishedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currentAssessment?: Maybe<SkillAssessment>;
  currentAssessmentId?: Maybe<Scalars['ID']>;
  /** @deprecated Use "yearsOfExperience" instead. */
  experience?: Maybe<SkillExperience>;
  skill: Skill;
  skillId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  validated?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use "validatedYearsOfExperience" instead. */
  validatedExperience?: Maybe<SkillExperience>;
  validatedYearsOfExperience?: Maybe<Scalars['Int']>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type ProfileSkillInput = {
  skillId: Scalars['ID'];
  yearsOfExperience: Scalars['Int'];
};

export type ProfileSkills__SkillIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  neq?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProfileSort = {
  direction: SortDirection;
  field: ProfileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProfileSortFields {
  Id = '_id',
  Availability = 'availability',
  EnglishProficiency = 'englishProficiency',
  EnglishScore = 'englishScore',
  ExpectedSalaryFrom = 'expectedSalaryFrom',
  ExpectedSalaryTo = 'expectedSalaryTo',
  FillingPercentage = 'fillingPercentage',
  LinkedinHandle = 'linkedinHandle',
  MainSkillsExperience = 'mainSkills__experience',
  MainSkillsSkillId = 'mainSkills__skillId',
  NotesNote = 'notes__note',
  ReferralCode = 'referralCode',
  ReferredByUserId = 'referredByUserId',
  ReviewStatus = 'reviewStatus',
  SkillsSkillId = 'skills__skillId',
  SoftSkillsScore = 'softSkillsScore',
  Status = 'status',
  Title = 'title',
  UserActive = 'user__active',
  UserCreatedAt = 'user__createdAt',
  UserEmail = 'user__email',
  UserFirstName = 'user__firstName',
  UserFullName = 'user__fullName',
  UserLastName = 'user__lastName',
  VettingFinishedAt = 'vettingFinishedAt',
  VettingStatus = 'vettingStatus',
  WorkableId = 'workableId',
  WorkableRecruiterName = 'workableRecruiterName',
  WorkableStage = 'workableStage',
  WorkableUrl = 'workableUrl',
  YearsOfExperience = 'yearsOfExperience'
}

export enum ProfileStatus {
  Active = 'ACTIVE',
  Available = 'AVAILABLE',
  Inactive = 'INACTIVE',
  Matching = 'MATCHING'
}

export type ProfileStatusFilterComparison = {
  eq?: InputMaybe<ProfileStatus>;
  gt?: InputMaybe<ProfileStatus>;
  gte?: InputMaybe<ProfileStatus>;
  iLike?: InputMaybe<ProfileStatus>;
  in?: InputMaybe<Array<ProfileStatus>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ProfileStatus>;
  lt?: InputMaybe<ProfileStatus>;
  lte?: InputMaybe<ProfileStatus>;
  neq?: InputMaybe<ProfileStatus>;
  notILike?: InputMaybe<ProfileStatus>;
  notIn?: InputMaybe<Array<ProfileStatus>>;
  notLike?: InputMaybe<ProfileStatus>;
};

export type ProfileVersionMetadata = {
  __typename?: 'ProfileVersionMetadata';
  pathsPendingUpdate?: Maybe<Scalars['JSONObject']>;
  version: Scalars['Int'];
};

export type ProfileVersionMetadataInput = {
  pathsPendingUpdate?: InputMaybe<Scalars['JSONObject']>;
  version: Scalars['Int'];
};

export type ProfileVettingStatusFilterComparison = {
  eq?: InputMaybe<VettingStatus>;
  in?: InputMaybe<Array<VettingStatus>>;
  neq?: InputMaybe<VettingStatus>;
  notIn?: InputMaybe<Array<VettingStatus>>;
};

export type PublicCertification = {
  __typename?: 'PublicCertification';
  _id: Scalars['ID'];
  authority?: Maybe<Scalars['String']>;
  displaySource?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type PublicEducation = {
  __typename?: 'PublicEducation';
  _id: Scalars['ID'];
  endDate?: Maybe<Scalars['DateTime']>;
  fieldOfStudy?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  school: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PublicJob = {
  __typename?: 'PublicJob';
  _id: Scalars['ID'];
  additionalInformation?: Maybe<Scalars['String']>;
  /** company's incentives and perks */
  benefits?: Maybe<Scalars['String']>;
  company?: Maybe<PublicJobCompany>;
  /** Contract duration in months */
  contractDurationInMonths?: Maybe<Scalars['Int']>;
  /** Minimum expected salary */
  expectedSalaryFrom?: Maybe<Scalars['Float']>;
  /** Maximum expected salary. */
  expectedSalaryTo?: Maybe<Scalars['Float']>;
  generalInformation?: Maybe<Scalars['String']>;
  /** must have requirements */
  mustHaveRequirements?: Maybe<Scalars['String']>;
  /** nice to have requirements */
  niceToHaveRequirements?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  responsibilities?: Maybe<Scalars['String']>;
  skills: Array<PublicJobSkill>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  workloadHoursPerWeek?: Maybe<Scalars['Int']>;
  /** years amount of contract experience duration */
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type PublicJobCompany = {
  __typename?: 'PublicJobCompany';
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type PublicJobSkill = {
  __typename?: 'PublicJobSkill';
  experienceYears?: Maybe<Scalars['Int']>;
  skill: PublicSkill;
};

export type PublicMatchingSkill = {
  __typename?: 'PublicMatchingSkill';
  _id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondaryImage?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
};

export type PublicMatchingSkillConnection = {
  __typename?: 'PublicMatchingSkillConnection';
  /** Array of edges. */
  edges: Array<PublicMatchingSkillEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PublicMatchingSkillEdge = {
  __typename?: 'PublicMatchingSkillEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PublicMatchingSkill */
  node: PublicMatchingSkill;
};

export type PublicMatchingSkillFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PublicMatchingSkillFilter>>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PublicMatchingSkillFilter>>;
  secondaryName?: InputMaybe<StringFieldComparison>;
};

export type PublicMatchingSkillSort = {
  direction: SortDirection;
  field: PublicMatchingSkillSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PublicMatchingSkillSortFields {
  Id = '_id',
  Name = 'name',
  SecondaryName = 'secondaryName'
}

export type PublicProfile = {
  __typename?: 'PublicProfile';
  certifications: Array<PublicCertification>;
  codeSamples: Array<ProfileCodeSample>;
  education: Array<PublicEducation>;
  englishProficiency?: Maybe<EnglishProficiency>;
  githubProfile?: Maybe<Scalars['String']>;
  linkedinProfile?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mainSkills: Array<PublicProfileSkill>;
  picture: Scalars['String'];
  skills: Array<PublicProfileSkill>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user: PublicUser;
  workExperience: Array<PublicWorkExperience>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type PublicProfileSkill = {
  __typename?: 'PublicProfileSkill';
  /** @deprecated Use "yearsOfExperience" instead. */
  experience?: Maybe<SkillExperience>;
  skill: PublicSkill;
  validated?: Maybe<Scalars['Boolean']>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type PublicSkill = {
  __typename?: 'PublicSkill';
  _id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondaryImage?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  active: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PublicWorkExperience = {
  __typename?: 'PublicWorkExperience';
  _id: Scalars['ID'];
  company?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  ongoing: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  canReadBlogContent: Scalars['Boolean'];
  checkDuplicatesInSourcing: Array<JobCandidate>;
  checkDuplicatesInSourcingDeprecated: Array<Sourcing>;
  exportBillingsData: FileObject;
  exportCompaniesData: FileObject;
  exportEngagementsData: FileObject;
  exportInvoicesData: FileObject;
  exportProfilesData: FileObject;
  exportUsersData: FileObject;
  exportVettingData: FileObject;
  findCompanyUserInviteById?: Maybe<CompanyUserInvitation>;
  getAllBillingCycles: BillingCycleConnection;
  getAllBillingCyclesArray: Array<BillingCycle>;
  getAllBillings: BillingConnection;
  getAllBillingsArray: Array<Billing>;
  getAllBlogAuthors: BlogAuthorAttributesData;
  getAllBlogCategories: CategoryAttributesData;
  getAllBlogCategoriesWithNestedGuides: CategoryWithGuidesAttributesData;
  getAllBlogCategoriesWithNestedPosts: CategoryWithPostsAttributesData;
  getAllBlogFAQs: BlogFaqAttributesData;
  getAllBlogGuides: GuideAttributesData;
  getAllBlogPosts: PostAttributesData;
  getAllBlogTags: BlogTagAttributesData;
  getAllCompanies: CompanyConnection;
  getAllCompaniesArray: Array<Company>;
  getAllCompanyUserInvitations: CompanyUserInvitationConnection;
  getAllCompanyUsers: UserConnection;
  getAllEngagements: EngagementConnection;
  getAllEngagementsArray: Array<Engagement>;
  getAllFinancialEntities: FinancialEntityConnection;
  getAllInvoices: InvoiceConnection;
  getAllInvoicesArray: Array<Invoice>;
  getAllInvoicesWithoutBilling: InvoiceConnection;
  getAllJobCandidates: JobCandidateConnection;
  getAllJobs: JobConnection;
  getAllLegalEntities: LegalEntityConnection;
  getAllMatchingProfiles: Array<MatchingProfile>;
  getAllProfiles: ProfileConnection;
  getAllProfilesArray: Array<Profile>;
  getAllSkills: Array<Skill>;
  getAllTerms: Array<Term>;
  getAllUsers: UserConnection;
  getAllUsersArray: Array<User>;
  getAllWorklogs: WorklogConnection;
  getBillingByCode: Billing;
  getBillingById: Billing;
  getBlogGuideBySlug: GuideAttributeData;
  getBlogMainPage: BlogMainPageAttributesData;
  getBlogPostBySlug: PostAttributeData;
  getCompanyById: Company;
  getCompanyLegalEntity?: Maybe<LegalEntity>;
  getCurrentTermById?: Maybe<Term>;
  getCurrentTermByRoleAndType?: Maybe<Term>;
  getCurrentTerms: Array<Term>;
  getEngagementByCode?: Maybe<Engagement>;
  getEngagementById?: Maybe<Engagement>;
  getFinancialEntityByTalentId?: Maybe<FinancialEntity>;
  getInvoiceById: Invoice;
  getJobById: Job;
  getJobCandidateById: JobCandidate;
  getJobPositionSuggestions: Array<Scalars['String']>;
  getLearningContent: LearningContent;
  getLearningContentBySkill: LearningContentInitialResults;
  getLegalEntity: LegalEntity;
  getMe: User;
  getMyActiveEngagement?: Maybe<Engagement>;
  getMyAvailableAdditionalSkills: SkillConnection;
  getMyBillingByCode: Billing;
  getMyBillingById: Billing;
  getMyBillingCycles: BillingCycleConnection;
  getMyBillings: BillingConnection;
  getMyBillingsArray: Array<Billing>;
  getMyCompany?: Maybe<Company>;
  getMyCurrentBillingCycle: BillingCycle;
  getMyCurrentEngagements: Array<Engagement>;
  getMyEngagementById: Engagement;
  getMyEngagements: EngagementConnection;
  getMyFinancialEntity?: Maybe<FinancialEntity>;
  getMyInvoices: InvoiceConnection;
  getMyNextBillingCycle: BillingCycle;
  getMyPendingBillingsAndBankInfo: PendingBillingsAndBankInfo;
  getMyProfile?: Maybe<Profile>;
  getMyWorklogs: WorklogConnection;
  getMyWorklogsTimeByDateAndCategory: WorklogsTimeByDateAndCategoryOutput;
  getMyWorklogsTotalTime: WorklogsTotalTimeOutput;
  getPlatformBankInfo: PlatformBankInfo;
  getProfileById?: Maybe<Profile>;
  getPublicJobById: PublicJob;
  getPublicProfileById?: Maybe<PublicProfile>;
  getRdStationAuthUrl?: Maybe<Scalars['String']>;
  getSignedURLToPostCompanyPicture: PresignPostUrlReturn;
  getSignedURLToPostEngagementServiceAgreement: PresignPostUrlReturn;
  getSignedURLToPostMyPicture: PresignPostUrlReturn;
  getSignedURLToPostProfilePicture: PresignPostUrlReturn;
  getSignedURLToPostUserPicture: PresignPostUrlReturn;
  getSkillById?: Maybe<Skill>;
  getSkillByName?: Maybe<Skill>;
  getSkills: PublicMatchingSkillConnection;
  getSourcings: JobCandidateConnection;
  getSourcingsDeprecated: SourcingConnection;
  getTermAcceptancesByUserId: Array<TermAcceptance>;
  getTermById?: Maybe<Term>;
  getUninvitedCompanyUsers: Array<User>;
  getUserByEmail?: Maybe<User>;
  getUserById?: Maybe<User>;
  getVettingSkills: Array<Skill>;
  getWebsiteAboutUsPage: WebsiteAboutUsPageAttributesData;
  getWebsiteCompanyPage: WebsiteCompanyPageAttributesData;
  getWebsitePressPage: WebsitePressPageAttributesData;
  getWebsiteTalentPage: WebsiteTalentPageAttributesData;
  getWorklogsFromCycle: Array<Worklog>;
  getWorklogsTimeByCategory: WorklogsTimeByCategoryOutput;
};


export type QueryCheckDuplicatesInSourcingArgs = {
  input: CheckSourcingDuplicateInput;
};


export type QueryCheckDuplicatesInSourcingDeprecatedArgs = {
  input: CheckDuplicateInput;
};


export type QueryExportBillingsDataArgs = {
  filter?: InputMaybe<BillingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BillingSort>>;
};


export type QueryExportCompaniesDataArgs = {
  filter?: InputMaybe<CompanyFilter>;
  optionalPopulates?: InputMaybe<Array<CompanyOptionalPopulates>>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<CompanySort>>;
};


export type QueryExportEngagementsDataArgs = {
  filter?: InputMaybe<EngagementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EngagementSort>>;
};


export type QueryExportInvoicesDataArgs = {
  filter?: InputMaybe<InvoiceFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InvoiceSort>>;
};


export type QueryExportProfilesDataArgs = {
  filter?: InputMaybe<ProfileFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ProfileSort>>;
};


export type QueryExportUsersDataArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryExportVettingDataArgs = {
  filter?: InputMaybe<ProfileFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ProfileSort>>;
};


export type QueryFindCompanyUserInviteByIdArgs = {
  invitationId: Scalars['ID'];
};


export type QueryGetAllBillingCyclesArgs = {
  filter?: InputMaybe<BillingCycleFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<BillingCycleSort>>;
};


export type QueryGetAllBillingCyclesArrayArgs = {
  filter?: InputMaybe<BillingCycleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BillingCycleSort>>;
};


export type QueryGetAllBillingsArgs = {
  filter?: InputMaybe<BillingFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<BillingSort>>;
};


export type QueryGetAllBillingsArrayArgs = {
  filter?: InputMaybe<BillingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BillingSort>>;
};


export type QueryGetAllBlogAuthorsArgs = {
  input: BlogAuthorQueryArgs;
};


export type QueryGetAllBlogCategoriesArgs = {
  input: BlogCategoryQueryArgs;
};


export type QueryGetAllBlogCategoriesWithNestedGuidesArgs = {
  input: BlogCategoryWithGuidesQueryArgs;
};


export type QueryGetAllBlogCategoriesWithNestedPostsArgs = {
  input: BlogCategoryWithPostsQueryArgs;
};


export type QueryGetAllBlogFaQsArgs = {
  input: BlogFaQsQueryArgs;
};


export type QueryGetAllBlogGuidesArgs = {
  input: BlogGuideQueryArgs;
};


export type QueryGetAllBlogPostsArgs = {
  input: BlogPostQueryArgs;
};


export type QueryGetAllBlogTagsArgs = {
  input: BlogTagsQueryArgs;
};


export type QueryGetAllCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  optionalPopulates?: InputMaybe<Array<CompanyOptionalPopulates>>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CompanySort>>;
};


export type QueryGetAllCompaniesArrayArgs = {
  filter?: InputMaybe<CompanyFilter>;
  optionalPopulates?: InputMaybe<Array<CompanyOptionalPopulates>>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<CompanySort>>;
};


export type QueryGetAllCompanyUserInvitationsArgs = {
  companyId: Scalars['ID'];
  filter?: InputMaybe<CompanyUserInvitationFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CompanyUserInvitationSort>>;
};


export type QueryGetAllCompanyUsersArgs = {
  companyId: Scalars['ID'];
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryGetAllEngagementsArgs = {
  filter?: InputMaybe<EngagementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EngagementSort>>;
};


export type QueryGetAllEngagementsArrayArgs = {
  filter?: InputMaybe<EngagementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EngagementSort>>;
};


export type QueryGetAllFinancialEntitiesArgs = {
  filter?: InputMaybe<FinancialEntityFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<FinancialEntitySort>>;
};


export type QueryGetAllInvoicesArgs = {
  filter?: InputMaybe<InvoiceFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<InvoiceSort>>;
};


export type QueryGetAllInvoicesArrayArgs = {
  filter?: InputMaybe<InvoiceFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InvoiceSort>>;
};


export type QueryGetAllInvoicesWithoutBillingArgs = {
  filter?: InputMaybe<InvoiceFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<InvoiceSort>>;
};


export type QueryGetAllJobCandidatesArgs = {
  filter?: InputMaybe<JobCandidateFilter>;
  jobId: Scalars['ID'];
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<JobCandidateSort>>;
};


export type QueryGetAllJobsArgs = {
  filter?: InputMaybe<JobFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<JobSort>>;
};


export type QueryGetAllLegalEntitiesArgs = {
  filter?: InputMaybe<LegalEntityFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<LegalEntitySort>>;
};


export type QueryGetAllMatchingProfilesArgs = {
  skillsId: Array<Scalars['ID']>;
};


export type QueryGetAllProfilesArgs = {
  filter?: InputMaybe<ProfileFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ProfileSort>>;
};


export type QueryGetAllProfilesArrayArgs = {
  filter?: InputMaybe<ProfileFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ProfileSort>>;
};


export type QueryGetAllUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryGetAllUsersArrayArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryGetAllWorklogsArgs = {
  filter?: InputMaybe<WorklogFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<WorklogSort>>;
};


export type QueryGetBillingByCodeArgs = {
  code: Scalars['Int'];
};


export type QueryGetBillingByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetBlogGuideBySlugArgs = {
  locale?: InputMaybe<Locale>;
  slug: Scalars['String'];
};


export type QueryGetBlogMainPageArgs = {
  input: BlogMainPageQueryArgs;
};


export type QueryGetBlogPostBySlugArgs = {
  locale?: InputMaybe<Locale>;
  slug: Scalars['String'];
};


export type QueryGetCompanyByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompanyLegalEntityArgs = {
  companyId: Scalars['ID'];
};


export type QueryGetCurrentTermByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCurrentTermByRoleAndTypeArgs = {
  input: FindCurrentByRoleAndTypeInput;
};


export type QueryGetEngagementByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetEngagementByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetFinancialEntityByTalentIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoiceByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetJobByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetJobCandidateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetJobPositionSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};


export type QueryGetLearningContentArgs = {
  input: LearningSearchInput;
};


export type QueryGetLearningContentBySkillArgs = {
  skillName: Scalars['String'];
};


export type QueryGetLegalEntityArgs = {
  legalEntityId: Scalars['ID'];
};


export type QueryGetMyAvailableAdditionalSkillsArgs = {
  filter?: InputMaybe<SkillFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SkillSort>>;
};


export type QueryGetMyBillingByCodeArgs = {
  code: Scalars['Int'];
};


export type QueryGetMyBillingByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetMyBillingCyclesArgs = {
  filter?: InputMaybe<BillingCycleFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<BillingCycleSort>>;
};


export type QueryGetMyBillingsArgs = {
  filter?: InputMaybe<BillingFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<BillingSort>>;
};


export type QueryGetMyBillingsArrayArgs = {
  filter?: InputMaybe<BillingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BillingSort>>;
};


export type QueryGetMyEngagementByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetMyEngagementsArgs = {
  filter?: InputMaybe<EngagementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EngagementSort>>;
};


export type QueryGetMyInvoicesArgs = {
  filter?: InputMaybe<InvoiceFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<InvoiceSort>>;
};


export type QueryGetMyWorklogsArgs = {
  filter?: InputMaybe<WorklogFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<WorklogSort>>;
};


export type QueryGetMyWorklogsTimeByDateAndCategoryArgs = {
  filter?: InputMaybe<WorklogFilter>;
};


export type QueryGetMyWorklogsTotalTimeArgs = {
  filter?: InputMaybe<WorklogFilter>;
};


export type QueryGetProfileByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPublicJobByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPublicProfileByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSignedUrlToPostCompanyPictureArgs = {
  companyId: Scalars['ID'];
  fileNameWithExtension: Scalars['String'];
};


export type QueryGetSignedUrlToPostEngagementServiceAgreementArgs = {
  engagementId: Scalars['ID'];
  fileNameWithExtension: Scalars['String'];
};


export type QueryGetSignedUrlToPostMyPictureArgs = {
  fileNameWithExtension: Scalars['String'];
};


export type QueryGetSignedUrlToPostProfilePictureArgs = {
  fileNameWithExtension: Scalars['String'];
};


export type QueryGetSignedUrlToPostUserPictureArgs = {
  fileNameWithExtension: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetSkillByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSkillByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetSkillsArgs = {
  filter?: InputMaybe<PublicMatchingSkillFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PublicMatchingSkillSort>>;
};


export type QueryGetSourcingsArgs = {
  filter?: InputMaybe<JobCandidateFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<JobCandidateSort>>;
};


export type QueryGetSourcingsDeprecatedArgs = {
  filter?: InputMaybe<SourcingFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SourcingSort>>;
};


export type QueryGetTermAcceptancesByUserIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetTermByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetUninvitedCompanyUsersArgs = {
  companyId: Scalars['ID'];
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetWebsiteAboutUsPageArgs = {
  input: WebsiteAboutUsPageQueryArgs;
};


export type QueryGetWebsiteCompanyPageArgs = {
  input: WebsiteCompanyPageQueryArgs;
};


export type QueryGetWebsitePressPageArgs = {
  input: WebsitePressPageQueryArgs;
};


export type QueryGetWebsiteTalentPageArgs = {
  input: WebsiteTalentPageQueryArgs;
};


export type QueryGetWorklogsFromCycleArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorklogsTimeByCategoryArgs = {
  filter?: InputMaybe<WorklogFilter>;
};

export enum ReferralType {
  Events = 'EVENTS',
  Other = 'OTHER',
  OurWebsite = 'OUR_WEBSITE',
  Referral = 'REFERRAL',
  SocialMedia = 'SOCIAL_MEDIA'
}

export type RemoveJobCandidateInput = {
  jobId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum RemoveMainSkill {
  MoveToAdditional = 'MOVE_TO_ADDITIONAL',
  RemoveFromProfile = 'REMOVE_FROM_PROFILE'
}

export enum Role {
  Backoffice = 'BACKOFFICE',
  Company = 'COMPANY',
  Global = 'GLOBAL',
  Talent = 'TALENT'
}

export type RoleFilterComparison = {
  eq?: InputMaybe<Role>;
  gt?: InputMaybe<Role>;
  gte?: InputMaybe<Role>;
  iLike?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Role>;
  lt?: InputMaybe<Role>;
  lte?: InputMaybe<Role>;
  neq?: InputMaybe<Role>;
  notILike?: InputMaybe<Role>;
  notIn?: InputMaybe<Array<Role>>;
  notLike?: InputMaybe<Role>;
};

export type Seo = {
  __typename?: 'SEO';
  id?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaSocial?: Maybe<MetaSocial>;
  metaTitle?: Maybe<Scalars['String']>;
};

export enum Seniority {
  Junior = 'JUNIOR',
  MidLevel = 'MID_LEVEL',
  Senior = 'SENIOR'
}

export type SeniorityFilterComparison = {
  eq?: InputMaybe<Seniority>;
  gt?: InputMaybe<Seniority>;
  gte?: InputMaybe<Seniority>;
  iLike?: InputMaybe<Seniority>;
  in?: InputMaybe<Array<Seniority>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Seniority>;
  lt?: InputMaybe<Seniority>;
  lte?: InputMaybe<Seniority>;
  neq?: InputMaybe<Seniority>;
  notILike?: InputMaybe<Seniority>;
  notIn?: InputMaybe<Array<Seniority>>;
  notLike?: InputMaybe<Seniority>;
};

export enum ServiceType {
  ProductDesign = 'PRODUCT_DESIGN',
  ProjectManagement = 'PROJECT_MANAGEMENT',
  SoftwareDevelopment = 'SOFTWARE_DEVELOPMENT'
}

export type ServiceTypeFilterComparison = {
  eq?: InputMaybe<ServiceType>;
  gt?: InputMaybe<ServiceType>;
  gte?: InputMaybe<ServiceType>;
  iLike?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ServiceType>;
  lt?: InputMaybe<ServiceType>;
  lte?: InputMaybe<ServiceType>;
  neq?: InputMaybe<ServiceType>;
  notILike?: InputMaybe<ServiceType>;
  notIn?: InputMaybe<Array<ServiceType>>;
  notLike?: InputMaybe<ServiceType>;
};

export type SetUserGroupsInput = {
  groups: Array<Group>;
  id: Scalars['ID'];
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number: Scalars['Int'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type ShippingAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number: Scalars['Int'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type SignUpInput = {
  acceptedTerms: Scalars['Boolean'];
  birthdate: Scalars['DateTime'];
  deviceId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
  signUpUtm?: InputMaybe<Scalars['JSONObject']>;
  signedUpForJobId?: InputMaybe<Scalars['String']>;
};

export type Skill = {
  __typename?: 'Skill';
  _id: Scalars['ID'];
  availableOnVetting: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hidden: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  secondaryImage?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workableShortCode?: Maybe<Scalars['String']>;
};

export type SkillAssessment = {
  __typename?: 'SkillAssessment';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  experience: SkillExperience;
  expiresAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  profile: Profile;
  profileId: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  skill: Skill;
  skillId: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
  testId?: Maybe<Scalars['String']>;
  testStatus?: Maybe<Scalars['String']>;
  testUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  validated: Scalars['Boolean'];
};

export type SkillConnection = {
  __typename?: 'SkillConnection';
  /** Array of edges. */
  edges: Array<SkillEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SkillEdge = {
  __typename?: 'SkillEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Skill */
  node: Skill;
};

export enum SkillExperience {
  BetweenOneAndTwo = 'BETWEEN_ONE_AND_TWO',
  BetweenSixAndTen = 'BETWEEN_SIX_AND_TEN',
  BetweenThreeAndFive = 'BETWEEN_THREE_AND_FIVE',
  LessThanOne = 'LESS_THAN_ONE',
  MoreThanTen = 'MORE_THAN_TEN'
}

export type SkillFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<SkillFilter>>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SkillFilter>>;
  secondaryName?: InputMaybe<StringFieldComparison>;
};

export type SkillSort = {
  direction: SortDirection;
  field: SkillSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SkillSortFields {
  Id = '_id',
  Name = 'name',
  SecondaryName = 'secondaryName'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourceOrigin {
  Other = 'OTHER',
  TalentPool = 'TALENT_POOL',
  Waitlist = 'WAITLIST'
}

export type SourceOriginFilterComparison = {
  eq?: InputMaybe<SourceOrigin>;
  gt?: InputMaybe<SourceOrigin>;
  gte?: InputMaybe<SourceOrigin>;
  iLike?: InputMaybe<SourceOrigin>;
  in?: InputMaybe<Array<SourceOrigin>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<SourceOrigin>;
  lt?: InputMaybe<SourceOrigin>;
  lte?: InputMaybe<SourceOrigin>;
  neq?: InputMaybe<SourceOrigin>;
  notILike?: InputMaybe<SourceOrigin>;
  notIn?: InputMaybe<Array<SourceOrigin>>;
  notLike?: InputMaybe<SourceOrigin>;
};

export type Sourcing = {
  __typename?: 'Sourcing';
  _id: Scalars['ID'];
  bitrixSourcingId?: Maybe<Scalars['Float']>;
  candidate: SourcingCandidate;
  createdAt: Scalars['DateTime'];
  job: SourcingJob;
  origin: SourceOrigin;
  recruiter: SourcingRecruiter;
  /** Still under definition */
  stage?: Maybe<Scalars['String']>;
};

export type SourcingCandidate = {
  __typename?: 'SourcingCandidate';
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  linkedinUrl: Scalars['String'];
};

export type SourcingCandidateInput = {
  _id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  linkedinUrl: Scalars['String'];
};

export type SourcingConnection = {
  __typename?: 'SourcingConnection';
  /** Array of edges. */
  edges: Array<SourcingEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SourcingEdge = {
  __typename?: 'SourcingEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Sourcing */
  node: Sourcing;
};

export type SourcingFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<SourcingFilter>>;
  candidate__fullName?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  job___id?: InputMaybe<SourcingJob___IdFilterComparison>;
  job__code?: InputMaybe<StringFieldComparison>;
  job__seniority?: InputMaybe<SourcingJob__SeniorityFilterComparison>;
  job__skills?: InputMaybe<SourcingJob__SkillsFilterComparison>;
  job__status?: InputMaybe<SourcingJob__StatusFilterComparison>;
  or?: InputMaybe<Array<SourcingFilter>>;
  origin?: InputMaybe<SourceOriginFilterComparison>;
  recruiter__fullName?: InputMaybe<StringFieldComparison>;
};

export type SourcingJob = {
  __typename?: 'SourcingJob';
  _id: Scalars['ID'];
  bitrixJobOpportunityId?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  position: Scalars['String'];
  seniority: Seniority;
  skills: Array<Scalars['String']>;
  status: JobStatus;
};

export type SourcingJob___IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
};

export type SourcingJob__SeniorityFilterComparison = {
  in?: InputMaybe<Array<Seniority>>;
};

export type SourcingJob__SkillsFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type SourcingJob__StatusFilterComparison = {
  eq?: InputMaybe<JobStatus>;
  in?: InputMaybe<Array<JobStatus>>;
};

export type SourcingRecruiter = {
  __typename?: 'SourcingRecruiter';
  _id: Scalars['ID'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  slackHandle?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
};

export type SourcingRecruiterInput = {
  _id: Scalars['ID'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  slackHandle?: InputMaybe<Scalars['String']>;
  slackUserId?: InputMaybe<Scalars['String']>;
};

export type SourcingSort = {
  direction: SortDirection;
  field: SourcingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SourcingSortFields {
  Id = '_id',
  CandidateFullName = 'candidate__fullName',
  CreatedAt = 'createdAt',
  JobId = 'job___id',
  JobCode = 'job__code',
  JobSeniority = 'job__seniority',
  JobSkills = 'job__skills',
  JobStatus = 'job__status',
  Origin = 'origin',
  RecruiterFullName = 'recruiter__fullName'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type StringFiltersPartial = {
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagsFilterArgsPartialWithConditionalFilters = {
  and?: InputMaybe<Array<TagsFiltersPartial>>;
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  or?: InputMaybe<Array<TagsFiltersPartial>>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type TagsFiltersPartial = {
  createdAt?: InputMaybe<DateFiltersPartial>;
  id?: InputMaybe<StringFiltersPartial>;
  name?: InputMaybe<StringFiltersPartial>;
  updatedAt?: InputMaybe<DateFiltersPartial>;
};

export type TagsSortArgsPartial = {
  createdAt?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  name?: InputMaybe<SortingDirection>;
  updatedAt?: InputMaybe<SortingDirection>;
};

export type TalentMoodPulse = IMoodPulse & {
  __typename?: 'TalentMoodPulse';
  _id: Scalars['ID'];
  comment: Scalars['String'];
  companyId: Scalars['ID'];
  date: Scalars['DateTime'];
  engagement: Engagement;
  role: Role;
  score: Scalars['Float'];
  userId: Scalars['ID'];
};

export enum TeamSize {
  FiftyToOneHundred = 'FIFTY_TO_ONE_HUNDRED',
  FiveHundredToOneThousand = 'FIVE_HUNDRED_TO_ONE_THOUSAND',
  MoreThanOneThousand = 'MORE_THAN_ONE_THOUSAND',
  OneHundredToFiveHundred = 'ONE_HUNDRED_TO_FIVE_HUNDRED',
  OneToTen = 'ONE_TO_TEN',
  TenToFifty = 'TEN_TO_FIFTY'
}

export type TeamSizeFilterComparison = {
  eq?: InputMaybe<TeamSize>;
  gt?: InputMaybe<TeamSize>;
  gte?: InputMaybe<TeamSize>;
  iLike?: InputMaybe<TeamSize>;
  in?: InputMaybe<Array<TeamSize>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<TeamSize>;
  lt?: InputMaybe<TeamSize>;
  lte?: InputMaybe<TeamSize>;
  neq?: InputMaybe<TeamSize>;
  notILike?: InputMaybe<TeamSize>;
  notIn?: InputMaybe<Array<TeamSize>>;
  notLike?: InputMaybe<TeamSize>;
};

export type Term = {
  __typename?: 'Term';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  current: Scalars['Boolean'];
  role: Role;
  textHtml?: Maybe<Scalars['String']>;
  type: TermType;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  versionDate: Scalars['DateTime'];
  versionName: Scalars['String'];
};

export type TermAcceptance = {
  __typename?: 'TermAcceptance';
  _id: Scalars['ID'];
  acceptanceDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  revoked: Scalars['Boolean'];
  revokedDate?: Maybe<Scalars['DateTime']>;
  term: Term;
  termId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum TermType {
  CookiePolicy = 'COOKIE_POLICY',
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsOfUse = 'TERMS_OF_USE'
}

export enum TimeTrackingTool {
  Harvest = 'HARVEST',
  Jira = 'JIRA',
  Toggl = 'TOGGL'
}

export type Topics = {
  __typename?: 'Topics';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type UpdateBillingCycleInput = {
  closed?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  expectedHoursPerWeek?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateBillingInput = {
  status: BillingStatus;
  statusDescription?: InputMaybe<Scalars['String']>;
  triggerEmail?: InputMaybe<Scalars['Boolean']>;
  triggerHuskyPayment?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBillingInvoiceStatusInput = {
  status: BillingStatus;
  statusDescription?: InputMaybe<Scalars['String']>;
};

export type UpdateByIdProfileCodeSampleInput = {
  id: Scalars['ID'];
  updateData: UpdateProfileCodeSampleInput;
};

export type UpdateByIdProfileSkillInputBackoffice = {
  id: Scalars['ID'];
  updateData: UpdateProfileSkillInputBackoffice;
};

export type UpdateCertificationInput = {
  id: Scalars['ID'];
  updateData: PartialCertificationInput;
};

export type UpdateCompanyInput = {
  businessType?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<UpdateContactInput>>;
  description?: InputMaybe<Scalars['String']>;
  engagementManagerId?: InputMaybe<Scalars['ID']>;
  engagementUserIds?: InputMaybe<Array<Scalars['ID']>>;
  joinedOn?: InputMaybe<Scalars['DateTime']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  pictureKey?: InputMaybe<Scalars['String']>;
  slackChannelUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatus>;
  teamSize?: InputMaybe<TeamSize>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateContactInput = {
  _id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateEducationInput = {
  id: Scalars['ID'];
  updateData: PartialEducationInput;
};

export type UpdateEngagementInput = {
  companyContractSigned?: InputMaybe<Scalars['Boolean']>;
  currentCycleId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  engagementManagerUserId?: InputMaybe<Scalars['ID']>;
  expectedHoursPerWeek?: InputMaybe<Scalars['Float']>;
  experienceStartDate?: InputMaybe<Scalars['DateTime']>;
  externalTimeTracking?: InputMaybe<ExternalTimeTrackingConfigInput>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  internalMetadata?: InputMaybe<Scalars['JSONObject']>;
  invoiceDuePeriod?: InputMaybe<Scalars['Int']>;
  invoiceDueType?: InputMaybe<InvoiceDueType>;
  jobCode?: InputMaybe<Scalars['String']>;
  monthlySalary?: InputMaybe<Scalars['Float']>;
  nextCycleId?: InputMaybe<Scalars['ID']>;
  offerMadeAt?: InputMaybe<Scalars['DateTime']>;
  paymentCycle?: InputMaybe<PaymentCycle>;
  paymentType?: InputMaybe<PaymentType>;
  serviceAgreementKeys?: InputMaybe<Array<Scalars['String']>>;
  serviceType?: InputMaybe<ServiceType>;
  slackChannelUrl?: InputMaybe<Scalars['String']>;
  sowNumber?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<EngagementStatus>;
  takeRate?: InputMaybe<Scalars['Float']>;
  talentContractSigned?: InputMaybe<Scalars['Boolean']>;
  workContractType?: InputMaybe<WorkContractType>;
};

export type UpdateFinancialEntityInput = {
  addressComplement?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cnpj?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hasSetupHusky?: InputMaybe<Scalars['Boolean']>;
  huskyConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  huskyId?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  talentFullName?: InputMaybe<Scalars['String']>;
};

export type UpdateInvoiceItemInput = {
  _id?: InputMaybe<Scalars['ID']>;
  chargeTakeRate?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
  workedHours?: InputMaybe<Scalars['Float']>;
};

export type UpdateJobCandidateActionInput = {
  communicationChannel?: InputMaybe<JobCandidateCommunicationChannel>;
  description?: InputMaybe<Scalars['String']>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateJobCandidateInput = {
  assigneeUserId?: InputMaybe<Scalars['ID']>;
  checklist?: InputMaybe<Scalars['String']>;
  nextAction?: InputMaybe<UpdateJobCandidateActionInput>;
  sharedProfile?: InputMaybe<Scalars['Boolean']>;
  sharedProfileDate?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<JobCandidateSource>;
};

export type UpdateJobCandidateStageInput = {
  archiveReason?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  stage: JobCandidateStage;
};

export type UpdateJobInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  benefits?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  contractDurationInMonths?: InputMaybe<Scalars['Int']>;
  contractType?: InputMaybe<ContractType>;
  engagementManagerUserId?: InputMaybe<Scalars['ID']>;
  expectedSalaryFrom?: InputMaybe<Scalars['Float']>;
  expectedSalaryTo?: InputMaybe<Scalars['Float']>;
  generalInformation?: InputMaybe<Scalars['String']>;
  hideSalaryFromPublic?: InputMaybe<Scalars['Boolean']>;
  mustHaveRequirements?: InputMaybe<Scalars['String']>;
  niceToHaveRequirements?: InputMaybe<Scalars['String']>;
  paymentCycle?: InputMaybe<PaymentCycle>;
  paymentType?: InputMaybe<PaymentType>;
  position?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Scalars['String']>;
  sellingPoints?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<Seniority>;
  skills?: InputMaybe<Array<CreateJobSkillInput>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  workloadHoursPerWeek?: InputMaybe<Scalars['Int']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']>;
};

export type UpdateJobStatusInput = {
  archiveReason?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  status: JobStatus;
};

export type UpdateLegalEntityInput = {
  addressComplement?: InputMaybe<Scalars['String']>;
  billingContacts?: InputMaybe<Array<UpdateContactInput>>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  employeeIdentificationNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type UpdatePlatformInvoiceInput = {
  code?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<PlatformInvoiceItemInput>>;
  status?: InputMaybe<InvoiceStatus>;
  statusDescription?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileCodeSampleInput = {
  description?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  availability?: InputMaybe<Availability>;
  careerPath?: InputMaybe<CareerPath>;
  englishProficiency?: InputMaybe<EnglishProficiency>;
  /** Minimum expected salary */
  expectedSalaryFrom?: InputMaybe<Scalars['Float']>;
  /** Maximum expected salary. */
  expectedSalaryTo?: InputMaybe<Scalars['Float']>;
  githubProfile?: InputMaybe<Scalars['String']>;
  linkedinHandle?: InputMaybe<Scalars['String']>;
  linkedinProfile?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  platformMessage?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  preferredRoles?: InputMaybe<Array<ProfilePreferredRole>>;
  referral?: InputMaybe<ProfileReferralInput>;
  resume?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<ShippingAddressInput>;
  story?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unavailableReason?: InputMaybe<Scalars['String']>;
  versionMetadata?: InputMaybe<ProfileVersionMetadataInput>;
  welcomeViewed?: InputMaybe<Scalars['Boolean']>;
  workingExperience?: InputMaybe<WorkingExperience>;
  yearsOfExperience?: InputMaybe<Scalars['Int']>;
};

export type UpdateProfileSkillInput = {
  id: Scalars['ID'];
  yearsOfExperience: Scalars['Int'];
};

export type UpdateProfileSkillInputBackoffice = {
  validated?: InputMaybe<Scalars['Boolean']>;
  validatedYearsOfExperience?: InputMaybe<Scalars['Int']>;
  yearsOfExperience?: InputMaybe<Scalars['Int']>;
};

export type UpdateSkillInput = {
  availableOnVetting?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Image URL */
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  /** Secondary Image URL */
  secondaryImage?: InputMaybe<Scalars['String']>;
  secondaryName?: InputMaybe<Scalars['String']>;
  workableShortCode?: InputMaybe<Scalars['String']>;
};

export type UpdateTalentInvoiceInput = {
  code?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  huskyBankTransferReceipt?: InputMaybe<Scalars['String']>;
  huskyErrors?: InputMaybe<Scalars['JSONObject']>;
  huskyId?: InputMaybe<Scalars['String']>;
  huskyStatus?: InputMaybe<InvoiceHuskyStatus>;
  items?: InputMaybe<Array<UpdateInvoiceItemInput>>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  rateBRL?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<InvoiceStatus>;
  statusDescription?: InputMaybe<Scalars['String']>;
  totalBRL?: InputMaybe<Scalars['Float']>;
};

export type UpdateTermInput = {
  current?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Role>;
  textHtml?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TermType>;
  /** PDF/Page URL */
  url?: InputMaybe<Scalars['String']>;
  versionDate?: InputMaybe<Scalars['DateTime']>;
  versionName?: InputMaybe<Scalars['String']>;
};

export type UpdateUserAttributes = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
};

export type UpdateUserGroupsInput = {
  group: Group;
  id: Scalars['ID'];
};

export type UpdateUserStatus = {
  email: Scalars['String'];
  status: AccountStatus;
};

export type UpdateWorkExperienceInput = {
  id: Scalars['ID'];
  updateData: PartialWorkExperienceInput;
};

export type UpdateWorklogInput = {
  category?: InputMaybe<WorklogCategory>;
  description?: InputMaybe<Scalars['String']>;
  overtimeJustification?: InputMaybe<Scalars['String']>;
  secondsSpent?: InputMaybe<Scalars['Int']>;
  workedAt?: InputMaybe<Scalars['DateTime']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  acceptedTerms: Scalars['Boolean'];
  active: Scalars['Boolean'];
  birthdate?: Maybe<Scalars['DateTime']>;
  canUseLearning?: Maybe<Scalars['Boolean']>;
  companies?: Maybe<Array<Company>>;
  confirmationEmailLastRequested?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  engagementCompanies?: Maybe<Array<Company>>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  groups: Array<Group>;
  invitation?: Maybe<CompanyUserInvitation>;
  lastName: Scalars['String'];
  passwordUpdatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  referredByCode?: Maybe<Scalars['String']>;
  role: Role;
  signedUpForJobId?: Maybe<Scalars['ID']>;
  slackHandle?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
  termsAcceptanceDate: Scalars['DateTime'];
  theme: UserTheme;
  updatedAt: Scalars['DateTime'];
  verifiedEmail: Scalars['Boolean'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the User */
  node: User;
};

export type UserFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  acceptedTerms?: InputMaybe<BooleanFieldComparison>;
  active?: InputMaybe<BooleanFieldComparison>;
  and?: InputMaybe<Array<UserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  fullName?: InputMaybe<StringFieldComparison>;
  groups?: InputMaybe<UserGroupsFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  role?: InputMaybe<RoleFilterComparison>;
  verifiedEmail?: InputMaybe<BooleanFieldComparison>;
};

export type UserGroupsFilterComparison = {
  in?: InputMaybe<Array<Group>>;
  notIn?: InputMaybe<Array<Group>>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Id = '_id',
  AcceptedTerms = 'acceptedTerms',
  Active = 'active',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  FullName = 'fullName',
  Groups = 'groups',
  LastName = 'lastName',
  Role = 'role',
  VerifiedEmail = 'verifiedEmail'
}

export enum UserTheme {
  Dark = 'DARK',
  DefaultBrowserSettings = 'DEFAULT_BROWSER_SETTINGS',
  Light = 'LIGHT'
}

export enum VettingStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Skipped = 'SKIPPED',
  WaitingList = 'WAITING_LIST'
}

export type VettingStatusEntry = {
  __typename?: 'VettingStatusEntry';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  status: VettingStatus;
  userId?: Maybe<Scalars['ID']>;
};

export type WebsiteAboutUsPageAttributes = {
  __typename?: 'WebsiteAboutUsPageAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  posts?: Maybe<Array<BlogPostEntry>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebsiteAboutUsPageAttributesData = {
  __typename?: 'WebsiteAboutUsPageAttributesData';
  data?: Maybe<WebsiteAboutUsPageAttributes>;
};

export type WebsiteAboutUsPageQueryArgs = {
  locale?: InputMaybe<Locale>;
};

export type WebsiteCompanyPageAttributes = {
  __typename?: 'WebsiteCompanyPageAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  posts?: Maybe<Array<BlogPostEntry>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebsiteCompanyPageAttributesData = {
  __typename?: 'WebsiteCompanyPageAttributesData';
  data?: Maybe<WebsiteCompanyPageAttributes>;
};

export type WebsiteCompanyPageQueryArgs = {
  locale?: InputMaybe<Locale>;
};

export type WebsitePressPageAttributes = {
  __typename?: 'WebsitePressPageAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  posts?: Maybe<Array<BlogPostEntry>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebsitePressPageAttributesData = {
  __typename?: 'WebsitePressPageAttributesData';
  data?: Maybe<WebsitePressPageAttributes>;
};

export type WebsitePressPageQueryArgs = {
  locale?: InputMaybe<Locale>;
};

export type WebsiteTalentPageAttributes = {
  __typename?: 'WebsiteTalentPageAttributes';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  posts?: Maybe<Array<BlogPostEntry>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebsiteTalentPageAttributesData = {
  __typename?: 'WebsiteTalentPageAttributesData';
  data?: Maybe<WebsiteTalentPageAttributes>;
};

export type WebsiteTalentPageQueryArgs = {
  locale?: InputMaybe<Locale>;
};

export enum WorkContractType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME'
}

export type WorkContractTypeFilterComparison = {
  eq?: InputMaybe<WorkContractType>;
  gt?: InputMaybe<WorkContractType>;
  gte?: InputMaybe<WorkContractType>;
  iLike?: InputMaybe<WorkContractType>;
  in?: InputMaybe<Array<WorkContractType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<WorkContractType>;
  lt?: InputMaybe<WorkContractType>;
  lte?: InputMaybe<WorkContractType>;
  neq?: InputMaybe<WorkContractType>;
  notILike?: InputMaybe<WorkContractType>;
  notIn?: InputMaybe<Array<WorkContractType>>;
  notLike?: InputMaybe<WorkContractType>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  _id: Scalars['ID'];
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  ongoing: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type WorkableStageEntry = {
  __typename?: 'WorkableStageEntry';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  stage: Scalars['String'];
};

export enum WorkingExperience {
  Associate = 'ASSOCIATE',
  MidLevel = 'MID_LEVEL',
  Principal = 'PRINCIPAL',
  Senior = 'SENIOR'
}

export type Worklog = {
  __typename?: 'Worklog';
  _id: Scalars['ID'];
  category: WorklogCategory;
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  cycle: BillingCycle;
  cycleId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  engagement: Engagement;
  engagementId: Scalars['ID'];
  externalTimeTracking?: Maybe<ExternalTimeTracking>;
  invoiceId?: Maybe<Scalars['ID']>;
  profile: Profile;
  secondsSpent: Scalars['Int'];
  talentUserId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  workedAt: Scalars['DateTime'];
};

export enum WorklogCategory {
  Development = 'DEVELOPMENT',
  Meetings = 'MEETINGS',
  Others = 'OTHERS'
}

export type WorklogCategoryFilterComparison = {
  eq?: InputMaybe<WorklogCategory>;
  gt?: InputMaybe<WorklogCategory>;
  gte?: InputMaybe<WorklogCategory>;
  iLike?: InputMaybe<WorklogCategory>;
  in?: InputMaybe<Array<WorklogCategory>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<WorklogCategory>;
  lt?: InputMaybe<WorklogCategory>;
  lte?: InputMaybe<WorklogCategory>;
  neq?: InputMaybe<WorklogCategory>;
  notILike?: InputMaybe<WorklogCategory>;
  notIn?: InputMaybe<Array<WorklogCategory>>;
  notLike?: InputMaybe<WorklogCategory>;
};

export type WorklogConnection = {
  __typename?: 'WorklogConnection';
  /** Array of edges. */
  edges: Array<WorklogEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type WorklogEdge = {
  __typename?: 'WorklogEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Worklog */
  node: Worklog;
};

export type WorklogFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<WorklogFilter>>;
  category?: InputMaybe<WorklogCategoryFilterComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdBy?: InputMaybe<IdFilterComparison>;
  cycleId?: InputMaybe<IdFilterComparison>;
  engagementId?: InputMaybe<IdFilterComparison>;
  invoiceId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorklogFilter>>;
  secondsSpent?: InputMaybe<IntFieldComparison>;
  talentUserId?: InputMaybe<IdFilterComparison>;
  workedAt?: InputMaybe<DateFieldComparison>;
};

export type WorklogSort = {
  direction: SortDirection;
  field: WorklogSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorklogSortFields {
  Id = '_id',
  Category = 'category',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  CycleId = 'cycleId',
  EngagementId = 'engagementId',
  InvoiceId = 'invoiceId',
  SecondsSpent = 'secondsSpent',
  TalentUserId = 'talentUserId',
  WorkedAt = 'workedAt'
}

export type WorklogsDatedTimeByCategoryOutput = {
  __typename?: 'WorklogsDatedTimeByCategoryOutput';
  date: Scalars['DateTime'];
  development: Scalars['Int'];
  meetings: Scalars['Int'];
  others: Scalars['Int'];
  totalSecondsSpent: Scalars['Int'];
};

export type WorklogsTimeByCategoryOutput = {
  __typename?: 'WorklogsTimeByCategoryOutput';
  development: Scalars['Int'];
  meetings: Scalars['Int'];
  others: Scalars['Int'];
  totalSecondsSpent: Scalars['Int'];
};

export type WorklogsTimeByDateAndCategoryOutput = {
  __typename?: 'WorklogsTimeByDateAndCategoryOutput';
  byDateAndCategory: Array<WorklogsDatedTimeByCategoryOutput>;
  totalSecondsSpent: Scalars['Int'];
};

export type WorklogsTotalTimeOutput = {
  __typename?: 'WorklogsTotalTimeOutput';
  totalSecondsSpent?: Maybe<Scalars['Int']>;
};

export enum Core__Purpose {
  /** `EXECUTION` features provide metadata necessary to for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export enum Join__Graph {
  Authentication = 'AUTHENTICATION',
  Company = 'COMPANY',
  Engagement = 'ENGAGEMENT',
  Payment = 'PAYMENT',
  Profile = 'PROFILE'
}
