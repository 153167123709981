import { createContext, useEffect, useState, useContext, useMemo } from 'react'
import { init, track, Types, setUserId, Identify, identify } from '@amplitude/analytics-browser'
import { useRouter } from 'next/router'
import { useUserQuery, useCompanyUserQuery, Role, ProfileStatus } from '@platform/data-access'

export type AmplitudeEventTrack = {
  eventInput: string | Types.BaseEvent
  eventProperties?: Record<string, any>
  eventOptions?: Types.EventOptions
}

type AmplitudeContextState = {
  trackEvent: (
    eventInput: string | Types.BaseEvent,
    eventProperties?: Record<string, any>,
    eventOptions?: Types.EventOptions
  ) => void | Types.AmplitudeReturn<Promise<Types.Result>>
  enabled: boolean
}

const INITIAL_STATE = {
  trackEvent: () => {
    return
  },
  enabled: false,
}

export const AmplitudeContext = createContext<AmplitudeContextState>(INITIAL_STATE)

export const useAmplitude = (apiKey?: string): AmplitudeContextState => {
  const router = useRouter()
  const [isAmplitudeInitialized, setAmplitudeInitialized] = useState<boolean>(false)
  const [amplitudeValue, setAmplitudeValue] = useState<AmplitudeContextState>(INITIAL_STATE)

  // Initialize Amplitude
  useEffect(() => {
    const initAmplitude = async () => {
      if (router.isReady && apiKey) {
        await init(apiKey).promise
        setAmplitudeInitialized(true)
      }
    }

    initAmplitude()
  }, [router.isReady]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAmplitudeInitialized) {
      setAmplitudeValue({
        trackEvent: (...args) => {
          track(...args)
        },
        enabled: true,
      })
    }
  }, [isAmplitudeInitialized])

  // Track router changes
  useEffect(() => {
    if (isAmplitudeInitialized) {
      track('app_load', {
        pathname: router.pathname,
        query: router.query,
      })
    }

    const registerRouteChange = (asPath: string) => {
      if (isAmplitudeInitialized) {
        track('page_change', {
          pathname: asPath,
        })
      }
    }

    router.events.on('routeChangeComplete', registerRouteChange)
    return () => {
      router.events.off('routeChangeComplete', registerRouteChange)
    }
  }, [isAmplitudeInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

  return amplitudeValue
}

const TALENT_STAGES = {
  [ProfileStatus.Active]: 'ACTIVE_ENGAGEMENT',
  [ProfileStatus.Matching]: 'TALENT_POOL',
  [ProfileStatus.Available]: 'TALENT_POOL',
  [ProfileStatus.Inactive]: 'INACTIVE',
}

export const AmplitudeUserSetup = () => {
  const { data: talentUserData } = useUserQuery(undefined, { enabled: false })
  const { data: companyUserData } = useCompanyUserQuery(undefined, { enabled: false })
  const { enabled } = useContext(AmplitudeContext)
  const identifyEvent = useMemo(() => new Identify(), [])

  const data = companyUserData?.getMe || talentUserData?.getMe
  const isCompany = data?.role === Role.Company
  const isTalent = data?.role === Role.Talent

  useEffect(() => {
    if (enabled && data?._id) {
      setUserId(data?._id)
      identifyEvent.set('user_role', data.role)

      if (isCompany && companyUserData?.getMe.companies?.[0]?.name) {
        identifyEvent.set('company_name', companyUserData?.getMe.companies?.[0]?.name)
      }

      if (isTalent) {
        const userStatus = talentUserData?.getMe?.profile?.status as ProfileStatus
        identifyEvent.set('talent_stage', TALENT_STAGES[userStatus])
      }
      identify(identifyEvent)
    }
  }, [
    companyUserData?.getMe.companies,
    data,
    enabled,
    identifyEvent,
    isCompany,
    isTalent,
    talentUserData?.getMe?.profile?.status,
  ])

  return null
}
