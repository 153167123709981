import NextLink, { LinkProps } from 'next/link'
import classnames from 'classnames'
import React, { forwardRef, useMemo, useRef, useImperativeHandle, Context } from 'react'
import {
  formatUrl,
  isExternalUrl,
  AmplitudeContext,
  useClickEventTracking,
  ContextType,
} from '@platform/utils'

import { ArrowIcon } from '../../assets/icons'

import styles from './index.module.scss'

type BaseProps = {
  className?: string
  href: string
  variant?: 'highlight' | 'primary' | 'secondary' | 'white' | 'opacity'
  size?: 'large' | 'medium'
  children?: React.ReactNode
  trackingIdentifier?: string
  prefetch?: boolean
  isExternal?: boolean
}

type LinkAsLink = BaseProps & Omit<LinkProps, keyof BaseProps>

type LinkAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps>

type CTAButtonProps = LinkAsExternal | LinkAsLink

export const CTAButton = forwardRef<HTMLAnchorElement, CTAButtonProps>(
  (
    {
      className = '',
      href,
      variant = 'highlight',
      size = 'large',
      children,
      trackingIdentifier,
      prefetch,
      isExternal = false,
      ...rest
    },
    ref
  ) => {
    const classes = classnames(styles['link'], styles[variant], styles[size], className)

    const innerRef = useRef<HTMLAnchorElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLAnchorElement)

    useClickEventTracking(
      innerRef,
      AmplitudeContext as unknown as Context<ContextType>,
      'website_button_click',
      {
        id: trackingIdentifier,
      }
    )

    const formattedUrl = isExternal ? href : formatUrl(href)

    const renderChildren = useMemo(() => {
      return (
        <span className={styles['content']}>
          <span className={styles['arrow-wrapper']}>
            <ArrowIcon className={styles['icon']} />
          </span>
          <span className={styles['button-label']}>{children}</span>
        </span>
      )
    }, [children])

    if (isExternalUrl(href) || isExternal) {
      return (
        <a
          href={formattedUrl}
          className={classes}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          {...rest}
        >
          {renderChildren}
        </a>
      )
    }

    return (
      <NextLink
        href={formattedUrl}
        prefetch={prefetch}
        className={classes}
        ref={innerRef as React.Ref<HTMLAnchorElement>}
        {...rest}
      >
        {renderChildren}
      </NextLink>
    )
  }
)
export default CTAButton
