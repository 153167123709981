import React, { useEffect, useContext, MutableRefObject, useState } from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

import { usePrevious } from './use-previous'

export type ContextType = {
  trackEvent: any
}

export const useClickEventTracking = <T,>(
  ref: MutableRefObject<HTMLElement | null>,
  context: React.Context<ContextType>,
  event: string,
  options?: T
): void => {
  const { trackEvent } = useContext(context)

  useEffect(() => {
    const nodeElement = ref?.current

    const trackClickEvent = () => {
      trackEvent(event, options)
    }

    nodeElement?.addEventListener('click', trackClickEvent)
    return () => {
      nodeElement?.removeEventListener('click', trackClickEvent)
    }
  }, [trackEvent, options, event, ref])
}

export const useInputTypingEventTracking = <T,>(
  ref: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>,
  context: React.Context<ContextType>,
  event: string,
  options?: T,
  shouldTrackTerms = false
): void => {
  const { trackEvent } = useContext(context)
  const [inputValue, setInputValue] = useState<string>('')
  const prevInputValue = usePrevious<string>(inputValue)

  useEffect(() => {
    const nodeElement = ref?.current

    const trackChangeEvent = AwesomeDebouncePromise(() => {
      const newValue = `${ref?.current?.value || ''}`
      setInputValue(newValue)
    }, 1000)

    nodeElement?.addEventListener('input', trackChangeEvent)
    return () => {
      nodeElement?.removeEventListener('input', trackChangeEvent)
    }
  }, [ref])

  useEffect(() => {
    if ((inputValue && inputValue !== prevInputValue) || (!inputValue && prevInputValue)) {
      trackEvent(event, {
        ...options,
        terms: shouldTrackTerms && inputValue,
      })
    }
  }, [inputValue, prevInputValue, event, options, trackEvent, shouldTrackTerms])
}
